import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FeedbackResponsePopupComponent } from '../feedback-response-popup/feedback-response-popup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-influencer-feedback',
  templateUrl: './influencer-feedback.component.html',
  styleUrls: ['./influencer-feedback.component.css']
})
export class InfluencerFeedbackComponent implements OnInit {
message : any;
  constructor(private dialog : MatDialog, public route : Router) { }

  ngOnInit() {
  }
  backtomsg(){
    this.route.navigate(['/home/content-approval'])
  }
  responsePopup(){
    if (this.message.trim().length === 0){
      return;
    }
      this.dialog.open(FeedbackResponsePopupComponent,{
        panelClass:['notapprovedmessage-box'],
      })
  }
}
