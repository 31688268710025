import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-contact-clout-popup',
  templateUrl: './contact-clout-popup.component.html',
  styleUrls: ['./contact-clout-popup.component.css']
})
export class ContactCloutPopupComponent implements OnInit {

  constructor(private dialogRef:MatDialog) { }

  ngOnInit() {
  }
  closeModal(){
    this.dialogRef.closeAll()
  }

}
