import { Component, OnInit } from '@angular/core';
import { FrontserviceService } from '../frontservice.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-archived-campaigns-listing',
  templateUrl: './archived-campaigns-listing.component.html',
  styleUrls: ['./archived-campaigns-listing.component.css']
})
export class ArchivedCampaignsListingComponent implements OnInit {

  pastcampaign : any []=[]
  clientid: any;
  constructor(public userser : FrontserviceService,private datepipe : DatePipe,public router : Router) { 
    // let today = new Date()
    let clientData:any =  JSON.parse(localStorage.getItem('clientProfile')).data?JSON.parse(localStorage.getItem('clientProfile')).data:'';

 this.clientid = clientData._id
    // let date = this.datepipe.transform(today,'dd-MM-yyyy')

    // console.log(date,'todaydate',new Date());
    this.userser.pastcampaign(this.clientid).then((val:any)=>{
      console.log(val,'pastCampaign')
      this.pastcampaign = val.data
      console.log(this.pastcampaign,'aaaaaa')
    })

  }

  ngOnInit() {
  }

  archieve(eve:any){
    let overview:any= {
      agemax: eve.agemax,
            agemin
            : 
            eve.agemin,
            campaignName
            : 
            eve.campaignName,
            campaigndate
            : 
            eve.campaigndate,
            campaignoverview
            : 
            eve.campaignoverview,
            clientId
            : 
            eve.clientId,
            competitors
            : 
            eve.competitors,
        
            duration
            : 
            eve.duration,
            gender
            : 
            eve.gender,
            
            type
            : 
            eve.contenttype,
          
          
            managment
            : 
            false,
            relevanthashtagsphrases
            : 
            eve.relevanthashtagsphrases,
            socialplatforms
            : 
            eve.socialplatforms,
          
            _id
            : 
            eve._id
     }
     localStorage.setItem('overview',JSON.stringify(overview))

    // const data = { id: eve.socialplatforms, name: 'John' };

    this.router.navigate(['/campaign'])
  }

}
