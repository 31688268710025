import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FileUpload } from "../fileupload-client";
import { FileService } from "../file.service";
import { ClientSnackbarComponent } from "../client-snackbar/client-snackbar.component";
import { FrontserviceService } from "../frontservice.service";

@Component({
  selector: "app-signup-influencer",
  templateUrl: "./signup-influencer.component.html",
  styleUrls: ["./signup-influencer.component.css"],
})
export class SignupInfluencerComponent implements OnInit {
  currentUpload: any = FileUpload;
  selectedFiles: any = FileList;
  // imageurl: any;

  client = {
    firstName: "",
    password: "",
    cpassword: "",
    email: "",
    role: "INFLUENCER",
    socialAccounts: [],
    profileImage: "",
    country: "",
    contactNumber: "",
    interest: [],
    acceptTerm: "",
  };
  inputfield: boolean = false;
  interestData: any[] = [];
  flag: boolean = false;
  submitted: boolean = false;
  emailfield: boolean = false;
  userExist: boolean = false;
  socialArr: Array<String> = ["Facebook", "Instagram", "Tik-Tok", "YouTube"];
  socialMediaAccountArr: Array<SocialAcc> = [{ username: "", handle: "" }];

  constructor(
    public userser: FrontserviceService,
    public route: Router,
    public snack: ClientSnackbarComponent,
    public FileUploadService: FileService
  ) {
    this.userser.getInterestData().then((val: any) => {
      this.interestData = val.data.interests || [];
    });
  }

  ngOnInit() {}
  emailAlredyExist = "";
  emailvalid(event: any) {
    var validRegex1 = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!this.client.email.match(validRegex1)) {
      // this.submitted =false;

      this.emailfield = true;
      return;
    } else {
      this.emailfield = false;
    }
  }

  inputvalid(event: any) {
    var validRegex = /^([0-9]\d*){6,15}$/g;
    if (!this.client.contactNumber.match(validRegex)) {
      // this.submitted = true;

      this.inputfield = true;
      return;
    } else {
      this.inputfield = false;
    }
  }

  addSocialAcc() {
    this.socialMediaAccountArr.push({ username: "", handle: "" });
  }

  submit(data: any) {
    // if(this.client.country == '' ){
    //  this.snack.snackbarSucces({message:'field required'})
    // }
    // this.newArr={...this.socialArr}
    // console.log(this.newArr)
    var validRegex1 = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var validRegex = /^([0-9]\d*){6,15}$/g;
    if (
      !this.client.acceptTerm ||
      !this.client.email ||
      !this.client.password ||
      !this.client.firstName ||
      !this.client.country ||
      !this.socialMediaAccountArr[0].handle ||
      !this.socialMediaAccountArr[0].username ||
      !this.client.contactNumber.match(validRegex) ||
      !this.client.email.match(validRegex1)
    ) {
      this.flag = true;
      this.submitted = true;
      return;
    } else {
      this.client.socialAccounts = this.socialMediaAccountArr;

      this.submitted = true;

      this.userser
        .influencerAdd(data)
        .then((val: any) => {
          // console.log(val,'123val', val.status)
          this.snack.snackbarSucces(
            val.message ? val.message : "Some Error Occured"
          );
          if (val.status == "success") {
            this.route.navigate(["/thankyou-influencer"]);
          }
        })
        .catch((err) => {
          // console.log(err.error.message,'123')
          if ((this.userExist = true)) {
            this.snack.snackbarSucces(
              (err.error.message 
                ? err.error.message
                : "user already exists")
            );
          }
        });
    }
  }

  onFileInputProfileImage1(event: any) {
    console.log(event);
    if (
      event.target.files[0].size < 5000000 &&
      (event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpg")
    ) {
      this.selectedFiles = event.target.files;
      let file = this.selectedFiles.item(0);
      this.currentUpload = new FileUpload(file);
      this.uploadImageProfileImage1();
    } else {
      // this.toast.toastError(
      //   'Allowed image format is jpeg,jpg or png with a max size 5 MB'
      // );
      // this.toastController.presentToast("Allowed image format is jpeg,jpg or png with a max size 5 MB")
      event.target.value = null;
    }
  }

  uploadImageProfileImage1() {
    this.FileUploadService.pushUpload(
      this.currentUpload,
      "/profileImage/"
    ).then((res: any) => {
      console.log(res);
      this.client.profileImage = res.url;
      // console.logthis.ionicForm.value.profileimage);
    });
  }
}

export interface SocialAcc {
  username: string;
  handle: string;
}
