import { Component, OnInit } from '@angular/core';
import{} from '@angular/router';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NotApprovedPopupComponent } from '../not-approved-popup/not-approved-popup.component';
import { FrontserviceService } from "../frontservice.service";

@Component({
  selector: 'app-index-message',
  templateUrl: './index-message.component.html',
  styleUrls: ['./index-message.component.css']
})
export class IndexMessageComponent implements OnInit {
  company:any;
  isClient:boolean = false
  isInfluencer:boolean = false
  userdata:any
  messages:any
  unreadmessage:boolean=false
  readmessage
  constructor(public route : Router , private dialog : MatDialog,
    public FrontserviceService:FrontserviceService) {
    let use:any = localStorage.getItem('clientProfile');
    use = use ? JSON.parse(use) : {}
    this.company = use.data.companyName;
    this.isClient = use.data.role==='CLIENT' ? true : false
    this.isInfluencer = use.data.role=== 'INFLUENCER' ? true : false
    this.userdata = JSON.parse(localStorage.getItem("clientProfile"));
    console.log(this.userdata?.data?._id,'this.userdata')

    this.getmessage()
   }
  // notapppopup(){
  //   {
  //     this.dialog.open(NotApprovedPopupComponent,{
  //       panelClass:['notapprovedmessage-box'],
  //     }) 
  //   }
  // }
      
  ngOnInit() {
  }
  getmessage(){
    this.FrontserviceService.getmessage(this.userdata?.data?._id).then((res:any)=>{
      this.messages = res?.data
      console.log(this.messages,'this.messages')

    })
  }
  navigateToMessage (id) {
    
 

    this.route.navigate(['/home/campaign-message/'+id])
  
  }
  unread(){
 
    
      this.FrontserviceService.getmessage(this.userdata?.data?._id).then((res:any)=>{
        this.unreadmessage = true;
        this.readmessage = false
        this.messages = res?.data
        this.messages = this.messages.filter(
          message => message.read === false);
      })


  }
  read(){
    this.FrontserviceService.getmessage(this.userdata?.data?._id).then((res:any)=>{
      this.readmessage = true
      this.unreadmessage = false;

      this.messages = res?.data
      this.messages = this.messages.filter(
        message => message.read === true);
    })

  }
  // navigateToInfluMsg(){
  //   if(this.isInfluencer){
  //   this.route.navigate(['/home/influencer-message'])
  //     }  }
}


