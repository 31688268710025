import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { AddCampaignComponent } from '../add-campaign/add-campaign.component';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { FrontDialogComponent } from '../front-dialog/front-dialog.component';
import { FrontserviceService } from '../frontservice.service';


export interface UserData {
  campaignName:any;
  productName:any;
  productdetail:any;
  socialplatforms:any;
  contenttype :any;
  campaigndate :any;
  duration:any;
  monthlybudget:any;
  campaignoverview :any;
  supportingdocuments :any;
  competitors :any;
  relevanthashtagsphrases:any;
  campaignStatus :any;
  clientId:any;
}

@Component({
  selector: 'app-client-campaign',
  templateUrl: './client-campaign.component.html',
  styleUrls: ['./client-campaign.component.css']
})
export class ClientCampaignComponent implements OnInit {

  displayedColumns: string[] = ['sn','campaignName','productName','productdetail','socialplatforms','contenttype','campaigndate','duration','monthlybudget','campaignoverview','supportingdocuments','competitors','relevanthashtagsphrases','campaignStatus','action'];
  dataSource= new MatTableDataSource<UserData>([]);

  campaign:any[]=[]

  searchvar:any

  pageSizes = [10, 25, 100];

  userId:any;


  constructor(private dialogref : MatDialog, public userser:FrontserviceService, public snack: ClientSnackbarComponent) {

    // let obj1 = {
    //   socialplatforms:'Instagram',
    //   contenttype :'Video',
    //   campaigndate :'2-02-2023',
    //   duration:'30 min',
    //   monthlybudget:'2000',
    //   campaignoverview :'Good',
    //   supportingdocuments :'Document',
    //   competitors :'Tiktok',
    //   relevanthashtagsphrases:'#Cool',
    //   campaignStatus :'Running',
    // }

    // this.campaign.push(obj1)

    // this.dataSource.data = this.campaign

    this.userId = JSON.parse(localStorage.getItem('clientProfile'))

    this.getall();

   }

   @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;

   ngAfterViewInit() {
   this.dataSource.paginator = this.paginatorPageSize;
   }

  ngOnInit() {
  }

  

  getall(){
    this.userser.getclientcampaign(this.userId.data._id).then((val:any)=>{
      console.log(val,'getting campaign')

      // for(let i=0;i<val.length;i++){

      //   if(this.userId.data._id === val[i].clientId)
      //   {
      //     this.campaign.push(val[i])
      //   }
      // }
      // console.log(this.campaign)
      this.dataSource.data = val
    })
  }

  dialogbox(){
    let dialogref = this.dialogref.open(AddCampaignComponent, {
     panelClass:'payment-pop',
     maxWidth:'100%',
     width:'60%',
     autoFocus: false,
     maxHeight: '90vh'
    });
    dialogref.afterClosed().subscribe((res:any)=>{
      console.log(res,'response');
      if(res){
      let obj = res.f;
      console.log(obj)
      this.userser.addcampaign(obj).then((val:any)=>{
        console.log(val,'add campaign')
        this.snack.snackbarSucces("Added Successfully")
        this.getall();
      })
      }
    })

  }

  edit(data:any, i:number){
    console.log(data,i,'edit data')
    let dialogref = this.dialogref.open(AddCampaignComponent, {
      panelClass:'payment-pop',
      maxWidth:'100%',
      width:'60%',
      autoFocus: false,
      maxHeight: '90vh',
      // data:{user:data}
 
     });
     dialogref.componentInstance.data = data
     dialogref.afterClosed().subscribe((res:any)=>{

      console.log(res.f,'edit')

      let obj = res.f;
      obj._id = data._id || ''

      this.userser.editcampaign(obj).then((val:any)=>{
        console.log(val,'edit campaign')
        this.snack.snackbarSucces("Updated Successfully")
        this.getall();
      })
    })

  }

  deleterow(data:any){

    let dialogref = this.dialogref.open(FrontDialogComponent,{
      panelClass:'payment-pop',
      width:"30%"
    })
    dialogref.afterClosed().subscribe((res:any)=>{
      console.log(res,'delete data')
  
    if(res.data === 'yes'){

      console.log(data,'deleteeeee')

      this.userser.deletecampaign(data._id).then((val:any)=>{
        console.log(val,'delete campaign')
        if(val){
          console.log(val,'delete campaign2')
          this.snack.snackbarSucces("Deleted Successfully")
           this.getall();
          }
      })
      }
    })
  }


  filtercall(){

    console.log(this.searchvar,'searchvar')

    this.dataSource.filter = this.searchvar.trim().toLowerCase();
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
  }

}
