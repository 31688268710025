import { RouteInfo } from './sidebar.metadata';
import { RouteInfoClient } from './sidebar.metadata';
import { RouteInfoInfluencer } from './sidebar.metadata';



// ========================
// Influencer Side Menu Bar 
// ========================

export const ROUTESSSS : RouteInfoClient[]=[
  {
    path: '/home/influencer-dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-apps',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
    subadminDisable : false
  },
]


// ====================
// Client Side Menu Bar 
// ====================

export const ROUTESS : RouteInfoClient[]=[
  {
    path: '/home/client-dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-apps',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
    subadminDisable : false
  },
  {
    path: '/home/client-campaign',
    title: 'Campaign',
    icon: 'mdi mdi-image-filter-none',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
    subadminDisable : false
  },

]

// ===================
// Admin Side Menu Bar 
// ===================

export const ROUTES: RouteInfo[] = [
  {
    path: '/admin/dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-apps',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
    subadminDisable : false
  },

  {
    path: '/admin/influencer-detail',
    title: 'Influencers',
    icon: 'mdi mdi-human-male-female',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
    subadminDisable : false
  },
  {
    path: '/admin/search',
    title: 'Influencers Search',
    icon: 'mdi mdi-account-search',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
    subadminDisable : false
  },
  // {
  //   path: '',
  //   title: 'Users',
  //   icon: 'mdi mdi-account',
  //   class: 'has-arrow',
  //   label: '',
  //   labelClass: '',
  //   extralink: false,
  //   subadminDisable : false,
  //   submenu:[
  //       {
  //           path: '/admin/users/',
  //           title: 'Users List',
  //           icon: 'mdi mdi-account-alert',
  //           class: '',
  //           label: '',
  //           labelClass: '',
  //           extralink: false,
  //           submenu:[
                
  //           ],
  //           subadminDisable : false
  //       },
  //       {
  //           path: '/admin/users/add',
  //           title: 'Add User',
  //           icon: 'mdi mdi-account-multiple-plus',
  //           class: '',
  //           label: '',
  //           labelClass: '',
  //           extralink: false,
  //           submenu:[
                
  //           ],
  //           subadminDisable : false
  //       }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Sub Admin',
  //   icon: 'mdi  mdi-account-star',
  //   class: 'has-arrow',
  //   label: '',
  //   labelClass: '',
  //   extralink: false,
  //   subadminDisable : true,
  //   submenu:[
  //       {
  //           path: '/admin/sub-admin/',
  //           title: 'Sub Admin List',
  //           icon: 'mdi mdi-view-list',
  //           class: '',
  //           label: '',
  //           labelClass: '',
  //           extralink: false,
  //           submenu:[
                
  //           ],
  //           subadminDisable : true
  //       },
  //       {
  //           path: '/admin/sub-admin/add',
  //           title: 'Add Sub Admin',
  //           icon: 'mdi mdi-server-plus',
  //           class: '',
  //           label: '',
  //           labelClass: '',
  //           extralink: false,
  //           submenu:[
                
  //           ],
  //           subadminDisable : true
  //       }
  //   ]
  // },

  {
    path: '/admin/client',
    title: 'Client',
    icon: 'mdi mdi-account-multiple',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    subadminDisable : true,
    submenu: [
    //   {
    //     path: '/admin/client',
    //     title: 'Client',
    //     icon: '',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu:[
            
    //     ],
    //     subadminDisable : true
    // },
  //   {
  //     path: '/admin/payment',
  //     title: 'Payment',
  //     icon: '',
  //     class: '',
  //     label: '',
  //     labelClass: '',
  //     extralink: false,
  //     submenu:[
          
  //     ],
  //     subadminDisable : true
  // },
//   {
//     path: '/admin/campaign',
//     title: 'Campaign',
//     icon: '',
//     class: '',
//     label: '',
//     labelClass: '',
//     extralink: false,
//     submenu:[
        
//     ],
//     subadminDisable : true
// },
    ],
  },
  {
    path: '/admin/sales',
    title: 'Campaign',
    icon: 'mdi mdi-image-filter-none',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
    subadminDisable : false
  },
];
