import { Injectable } from '@angular/core';
import { FileUpload } from '../shared/classes/file-upload';
import { environment } from '../../environments/environment';
import { LoadingService } from "../services/loading/loading.service";

declare var AWS: any;

@Injectable({
  providedIn: 'root'
})
export class FileService {
  uploadPercentage: any = '';
  bucket:any = new AWS.S3(environment.aws);
  constructor(private loading: LoadingService) {}
  pushUpload(upload: any, subfolderPath: string) {
    let name = new Date().getTime()+'-' + upload.file.name;

    const params = {
      Bucket: 'influncer',
      Key: 'influencer' + subfolderPath + name,
      Body: upload.file,
      ContentType: upload.file.type,
      ACL: 'public-read-write',
    };

    return new Promise((resolve, reject) => {
      this.loading.showLoading();
      let self = this;
      this.bucket
        .upload(params)
        // .on('httpUploadProgress', function (evt: any) {
        //   self.uploadPercentage = ((evt.loaded / evt.total) * 100).toFixed(0);
        //   console.log(self.uploadPercentage);
        //   upload.progress = ((evt.loaded / evt.total) * 100).toFixed(0);
        // })
        .send(function (err: any, data: any) {
          if (err) {
            self.loading.hideLoading();
            reject(err);
          }
          if (data) {
            resolve({ url: data.Location });
            self.loading.hideLoading();
          }
        });
    });
  }

  deleteUpload(upload: FileUpload, subfolderPath: string) {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}
