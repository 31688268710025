import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content-approval',
  templateUrl: './content-approval.component.html',
  styleUrls: ['./content-approval.component.css']
})
export class ContentApprovalComponent implements OnInit {

  constructor(public route: Router) { }

  ngOnInit() {
  }
  navigateToMessage () {
    
    this.route.navigate(['/content-approval/influencer-feedback'])
   
    }
    navigateToPost(){
      this.route.navigate(['/campaign/post-preview'])
    }
}
