import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { } from '@angular/router';
import { Router } from '@angular/router';
import { ReviewPostPopupComponent } from '../review-post-popup/review-post-popup.component';
import { MessageSentPopupComponent } from '../message-sent-popup/message-sent-popup.component';
import { FileService } from '../file.service';
import { FileUpload } from '../fileupload-client';
import { Campaign } from '../campaign-form/campaign-model';
import { FormGroup } from '@angular/forms';
import { FrontserviceService } from '../frontservice.service';
import { ProgresssavedPopupComponent } from '../progresssaved-popup/progresssaved-popup.component';
import { PostSchedulePopupComponent } from '../post-schedule-popup/post-schedule-popup.component';

@Component({
  selector: 'app-post-preview',
  templateUrl: './post-preview.component.html',
  styleUrls: ['./post-preview.component.css']
})
export class PostPreviewComponent implements OnInit {
  isClient:boolean = false;
  isInfluencer:boolean = false;
  campaignData: any = localStorage.getItem('overview') ? JSON.parse(localStorage.getItem('overview')) : {};
  influencerData: any = localStorage.getItem('clientProfile') ? JSON.parse(localStorage.getItem('clientProfile')).data : {};
  clientid: string = this.influencerData._id ? this.influencerData._id : '';
  response: string = 'pending';
  currentUpload: any = FileUpload;
  activeData = 0;
  post: any = []
  myform: FormGroup;
  imgInvalid = false
 
  "status": "PENDING"
  postData:any = [
    {
      'post': 0,
      'postData': {
        "copy": "",
        "hashTag": "",
        "postingDate": "",
        "postingTime": "",
        "files": [],
        "savedInfluencer": false,
        "scheduled": false,
        "status": "PENDING"
      }
    }
  ]

  constructor(private userser: FrontserviceService, public route: Router, private dialog: MatDialog, private FileUploadService: FileService) {
    // console.log(this.campaignData, 'campaignData', this.influencerData)
    let use:any = localStorage.getItem('clientProfile');
    use = use ? JSON.parse(use) : {}
    this.isClient = use.data.role==='CLIENT' ? true : false;
    this.isInfluencer = use.data.role==='INFLUENCER' ? true : false;

      this.userser.compaignPosts(this.campaignData._id).then((res: any) => {
        let influencerId = this.influencerData.role == 'CLIENT' ? localStorage.getItem('postInfluencerId') : this.influencerData._id
        let index = res.data.influencers.findIndex((x:any) => x.influencerId == influencerId)
        if (index != -1) {
          console.log(res.data.influencers[index].postId.postData, 'data')
          if (res.data.influencers[index].postId.postData) {
            this.postData = [];
            res.data.influencers[index].postId.postData.forEach((item: any, index: number) => {
              let obj = {
                'post': index,
                'postData': { ...item }
              }
              this.postData.push(obj)
            })
          }

        }
      })
  }

  removeFile(postDataIndex:any, fileIndex:any) {
    if(this.influencerData.role=='INFLUENCER') this.postData[postDataIndex].postData.files.splice(fileIndex, 1);
  }

  reviewpopup() {
    {
      this.dialog.open(ReviewPostPopupComponent, {
        panelClass: ['Reviewpopup-box'],
      })
    }
  }

  commissionpopup() {
    {
      this.dialog.open(MessageSentPopupComponent, {
        panelClass: ['Reviewpopup-box'],
      })  
    }
  }



  ngOnInit() {
  }
  onFileInput(files: FileList) {
    console.log('rohit')

    if (files.item(0).size < 5000000) {
      let file = files.item(0);
      this.currentUpload = new FileUpload(file);
      this.uploadFile();
    } else {
      // this.toast.toastError(
      //   'Allowed image format is jpeg,jpg or png with a max size 5 MB'
      // );
      // this.toastController.presentToast("Allowed image format is jpeg,jpg or png with a max size 5 MB")
      files = null;
    }

  }

  uploadFile() {
    this.FileUploadService.pushUpload(this.currentUpload, '/campaign-data/')
      .then((res: any) => {
        console.log(res, 'res')
        if (res.url) {
          this.postData[this.activeData].postData.files.push(res.url);

        }
      });
  }
  openPost() {
    this.postData.push({ 'post': this.postData.length, 'postData': { "copy": "", "hashTag": "", "postingDate": "", "postingTime": "", "files": [], "savedInfluencer": false, "scheduled": false, "status": "PENDING" }})
    console.log(this.postData, 'postdata')

  }
  active(i: any) {
    this.imgInvalid=false
    this.activeData = i
    console.log(this.postData[this.activeData].postData.files,'data')
    // this.postData[i].postData
  }
  saveData() {
  
    this.postData[this.activeData].postData.savedInfluencer = true;
    // this.postData[this.activeData].postData.scheduled = this.postData[this.activeData].postData.scheduled;
    let data = {
      "postData": this.postData[this.activeData].postData,
      "influencerId": this.influencerData._id,
      "campaignId": this.campaignData._id
    }
    this.userser.savePost(data).then((res: any) => {
      if(res.success){
        
        this.postData = [];
        res.data.postData.postData.forEach((item: any, index: number) => {
          let obj = {
            'post': index,
            'postData': { ...item }
          }
          this.postData.push(obj)
        })
        console.log(this.postData);

      }   
    })
    console.log(this.postData[this.activeData].postData, 'final')
    
      this.dialog.open(ProgresssavedPopupComponent, {
        panelClass: ['Progresssaved-box'],
      })
    
  }
  scheduleData() {
   
    this.postData[this.activeData].postData.savedInfluencer = true;
    this.postData[this.activeData].postData.scheduled = true;
    let data = {
      "postData": this.postData[this.activeData].postData,
      "influencerId": this.influencerData._id,
      "campaignId": this.campaignData._id
    }
    this.userser.savePost(data).then((res: any) => {
      if(res.success){
        this.postData = [];
        res.data.postData.postData.forEach((item: any, index: number) => {
          let obj = {
            'post': index,
            'postData': { ...item }
          }
          this.postData.push(obj)
        })
        console.log(this.postData);
        
      }
    })
    this.dialog.open(PostSchedulePopupComponent,{
      panelClass: ['Progresssaved-box'],
    })
  }

}
