import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-post-schedule-popup',
  templateUrl: './post-schedule-popup.component.html',
  styleUrls: ['./post-schedule-popup.component.css']
})
export class PostSchedulePopupComponent implements OnInit {

  constructor(private dialog : MatDialog) { }

  ngOnInit() {
  }
  cancel(){
    this.dialog.closeAll()
 }
}
