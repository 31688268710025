import { Component, OnInit } from '@angular/core';
import { CheckoutService } from '../../services/checkout.service';
import { MatDialog } from '@angular/material';
import { SubscriptionPopupComponent } from '../subscription-popup/subscription-popup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stripe-success',
  templateUrl: './stripe-success.component.html',
  styleUrls: ['./stripe-success.component.css']
})
export class StripeSuccessComponent implements OnInit {

  stripeSessionId: string = localStorage.getItem('stripeSessionId');
  constructor(public checkout: CheckoutService, private dialog: MatDialog,
    private route: Router) {
    let result = this.checkout.updateSubscription(this.stripeSessionId);
  }

  ngOnInit() {
    this.paymentSuccessDialog()
  }

  ngOnDestroy() {
    localStorage.removeItem('stripeSessionId');
  }

  paymentSuccessDialog() {
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {

    });
    dialogRef.afterClosed().subscribe(() => {
      this.route.navigate(['home/live-campaign'])
    });

  }
}
