import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clout-pricing',
  templateUrl: './clout-pricing.component.html',
  styleUrls: ['./clout-pricing.component.css']
})
export class CloutPricingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
