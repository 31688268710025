import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FollowersCountPipe } from "./followers-count.pipe";

@NgModule({
  declarations: [
    FollowersCountPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [ 
    FollowersCountPipe
  ],
})
export class PipeModule { }
