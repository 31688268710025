import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { FrontserviceService } from '../frontservice.service';

@Component({
  selector: 'app-thankyou-influencer',
  templateUrl: './thankyou-influencer.component.html',
  styleUrls: ['./thankyou-influencer.component.css']
})
export class ThankyouInfluencerComponent {


}
