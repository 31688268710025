import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-subscription-popup',
  templateUrl: './subscription-popup.component.html',
  styleUrls: ['./subscription-popup.component.css']
})
export class SubscriptionPopupComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  closeModal(){
    this.dialog.closeAll()
  }

}
