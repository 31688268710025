import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES, ROUTESSSS } from './menu-items';
import { ROUTESS } from './menu-items';
import { Router, ActivatedRoute } from '@angular/router';
import { AppSettings } from '../../core/constant';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']

})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];

  isSubAdmin : boolean = false;
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.getLoginUserData();
  }
  // End open close
  ngOnInit() {
    // console.log(this.route.url._subscribe.name,'routeeeeee')
    if(this.router.url == "/admin/dashboard" || this.router.url == '/admin/client' || this.router.url == '/admin/profile' ||
    this.router.url == '/admin/sales' || this.router.url == '/admin/influencer-detail' || this.router.url == '/admin/search'){
      
      this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
    else if(this.router.url == '/home/client-dashboard' || this.router.url == '/home/client-campaign' || this.router.url == '/home/profile'){

      this.sidebarnavItems = ROUTESS.filter(sidebarnavItem => sidebarnavItem);
    }
    else if(this.router.url == '/home/influencer-dashboard' || this.router.url == '/home/influencer-profile'){

      this.sidebarnavItems = ROUTESSSS.filter(sidebarnavItem => sidebarnavItem);
    }
  }


  getLoginUserData(){

    // let userProfile = JSON.parse(localStorage.getItem("userProfile"));
    
    //  let userRoleId = (userProfile['roleMappings'] !== undefined ) ? userProfile['roleMappings'][0]['roleId'] : '';
    
    //  if(userRoleId == AppSettings.ROLE.SUBADMIN){
    //   this.isSubAdmin = true;
    //  }
	  	
	}
}
