import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  company:any;
  isClient:boolean = false
  isInfluencer:boolean = false
  act:any;
  constructor(public route : Router) { 
    let use:any = localStorage.getItem('clientProfile');
    use = use ? JSON.parse(use) : {}
    this.company = use.data.companyName;
    this.isClient = use.data.role==='CLIENT' ? true : false
    this.isInfluencer = use.data.role=== 'INFLUENCER' ? true : false
  }
  hidden = true;

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }
  ngOnInit(): void {
   this.act = this.route.url;
  }

  logout(){
      window.localStorage.clear()

      this.route.navigate(['/home'])
  }
}
