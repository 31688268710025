import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';

import { UsersService } from '../../services/users/users.service';
import { Router } from '@angular/router';


import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
declare var $: any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls:['./navigation.css']

// styleUrls:'./navigation.component.css',
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
userProfile: any;
userName:any='';
clientdata:any;
role:any;
  public config: PerfectScrollbarConfigInterface = {};
  constructor(private modalService: NgbModal,
private userService : UsersService,
private router : Router,
    ) {
      this.role = localStorage.getItem('user')
      console.log(this.role , this.userProfile)
      
      if(this.role == 'CLIENT'){
        debugger
        this.clientdata = JSON.parse(localStorage.getItem('userProfile'));
        console.log(this.clientdata.data,'client profile')
        this.userName = this.clientdata.data.firstName
      }
      else if(this.role == 'Admin'){
      this.userProfile = JSON.parse(localStorage.getItem('userProfile'));
      this.userName = this.userProfile.myuser.name
      debugger
    }
    else if(this.role == 'influencer'){
      this.clientdata = JSON.parse(localStorage.getItem('userProfile'));
      console.log(this.clientdata.data,'influencer profile')
      this.userName = this.clientdata.data.firstName
    }
  }

  public showSearch = false;

  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

 

   doLogout(){
      // this.userService.logout().then(data=>{

      if(this.router.url == "/admin/dashboard" || this.router.url == '/admin/client' || this.router.url == '/admin/profile' ||
      this.router.url == '/admin/sales' || this.router.url == '/admin/influencer-detail' || this.router.url == '/admin/search'){
        
        window.localStorage.clear()
        // this.goToLogin();
        this.router.navigate(['/admin/login']);
      }
      else if(this.router.url == '/home/client-dashboard' || this.router.url == '/home/client-campaign' || this.router.url == '/home/profile'){
  
        window.localStorage.clear()

        this.router.navigate(['/home/login'])
      }
      else if(this.router.url == '/home/influencer-dashboard' || this.router.url == '/home/influencer-profile'){
  
        window.localStorage.clear()

        this.router.navigate(['/home/login'])
      }

      // if(this.role = 'Admin')
      // {
      //   console.log('admin login')
      //   window.localStorage.clear()
      //   // this.goToLogin();
      //   this.router.navigate(['/admin/login']);
      // }
      // else if(this.role = 'client')
      // {

      //   console.log('client login')
      //   window.localStorage.clear()

      //   this.router.navigate(['/home/login'])
      // }
      // })
    }

    profile(){
      if(this.router.url == "/admin/dashboard" || this.router.url == '/admin/client' || 
      this.router.url == '/admin/sales' || this.router.url == '/admin/influencer-detail' || this.router.url == '/admin/search'){
        
        this.router.navigate(['/admin/profile']);
      }
      else if(this.router.url == '/home/client-dashboard' || this.router.url == '/home/client-campaign'){
  
        this.router.navigate(['/home/profile'])
      }
      else if(this.router.url == '/home/influencer-dashboard'){
  
        this.router.navigate(['/home/influencer-profile'])
      }
    }

     goToLogin(){
          this.router.navigate(['/admin/login']);
        }

  ngAfterViewInit() {}
}
