import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { FileUpload } from '../fileupload-client';
import { FileService } from '../file.service';

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.css']
})
export class AddCampaignComponent implements OnInit {

  currentUpload: any = FileUpload;
	selectedFiles: any = FileList;

  @Input() data:any
  user = {
    firstName: '',
    lastName:'',
    password:  '',
    cpassword:'',
    email: '',
    _id:'',
    active:''
};
  submitted:boolean = false

  header:any='';
  userId:any;
  
  form= new FormGroup({
    campaignName: new FormControl('', [Validators.required]),
    productName: new FormControl('', [Validators.required]),
    productdetail: new FormControl('', [Validators.required]),
    socialplatforms: new FormControl('', [Validators.required]),
    contenttype: new FormControl('',[Validators.required]),
    campaigndate: new FormControl('',[Validators.required]),
    duration:new FormControl('',[Validators.required]),
    _id:new FormControl('',[]),
    monthlybudget:new FormControl('',[Validators.required]),
    campaignoverview:new FormControl('',[Validators.required]),
    supportingdocuments:new FormControl('',[Validators.required]),
    competitors:new FormControl('',[Validators.required]),
    relevanthashtagsphrases:new FormControl('',[Validators.required]),
    campaignStatus:new FormControl('',[Validators.required]),
    __v:new FormControl('',[]),
    clientId:new FormControl('',[])
  })
  
    constructor(private dialogRef : MatDialogRef<AddCampaignComponent>, public route : Router, public snack: ClientSnackbarComponent,
      public FileUploadService: FileService) { 
  
     this.userId=JSON.parse(localStorage.getItem('clientProfile'))
     console.log(this.userId.data._id)
    //  this.form.setValue(this.userId.data._id)
    this.form.controls['clientId'].setValue(this.userId.data._id);
      // this.form.value.clientId = this.userId.data._id;
    }
  
    ngOnInit() {
      console.log("data4545",this.data);
      
      if(this.data){
        console.log('data1')
        this.header = 'Edit Campaign';
        this.form.setValue(this.data);
        }       
      else{
        console.log('data2')
        this.header ='Add Campaign';
      }
    }

    onFileInput(event: any) {
      console.log(event);
      if (
        event.target.files[0].size < 5000000 &&
        (event.target.files[0].type === 'image/jpeg' ||
          event.target.files[0].type === 'image/png' ||
          event.target.files[0].type === 'image/jpg')
      ) {
        this.selectedFiles = event.target.files;
        let file = this.selectedFiles.item(0);
        this.currentUpload = new FileUpload(file);
        this.uploadImageProfileImage1();
      } else {
        // this.toast.toastError(
        //   'Allowed image format is jpeg,jpg or png with a max size 5 MB'
        // );
        // this.toastController.presentToast("Allowed image format is jpeg,jpg or png with a max size 5 MB")
        event.target.value = null;
      }
    }
  
    uploadImageProfileImage1() {
      this.FileUploadService.pushUpload(
        this.currentUpload,
        '/profileImage/'
      ).then((res: any) => {
        console.log(res);
        this.form.controls['supportingdocuments'].setValue(res.url);
        // console.logthis.ionicForm.value.profileimage);
      });
    }
  
    onSubmit(f:any) {
      this.submitted = true
      if(this.form.status !== "INVALID"){
      console.log(f,this.form,'form value');  // { first: '', last: '' }
      this.dialogRef.close({f});
      // this.snack.snackbarSucces('Successfully Added');
      }
      // this.route.navigate(['/admin/influencer-detail'],{queryParams:f})
    }
  
    cancel(){
      this.dialogRef.close(undefined);
    }
  
  }
  