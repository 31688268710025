import { Component, OnInit } from '@angular/core';
import { FrontserviceService } from '../frontservice.service';

@Component({
  selector: 'app-campaigns-overview',
  templateUrl: './campaigns-overview.component.html',
  styleUrls: ['./campaigns-overview.component.css']
})
export class CampaignsOverviewComponent implements OnInit {

  overview:any = JSON.parse(localStorage.getItem('overview'))
  userData:any = JSON.parse(localStorage.getItem('clientProfile'));
  constructor(public useruser:FrontserviceService) {
    
   }

  ngOnInit() {
    console.log('run')

  }
  downloadFile(blob, fileName)
  {
    console.log(blob,'blob')
      var a:any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
  
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
  }
  
  saveData(){
    var xhr = new XMLHttpRequest();
    console.log(this.overview.supportingdocuments,'this.overview.supportingdocuments')
    xhr.open("GET", this.overview.supportingdocuments);
    
    xhr.responseType = "blob";
    xhr.send();
    console.log(xhr,'xhr.response')
    setTimeout(() => {
      
      this.downloadFile(xhr.response, 'Attachment');
    }, 1000);
  }
  


}

