import { Component, OnInit } from '@angular/core';
import { FrontserviceService } from '../frontservice.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-live-campaign-listing',
  templateUrl: './influencer-new-opportunities.component.html',
  styleUrls: ['./influencer-new-opportunities.component.css']
})
export class InfluencerNewOpportunitiesComponent implements OnInit {

  livecam:any [] = [];
  newOpportunity : any []=[]
  clientid: any;
  clientData:any =  JSON.parse(localStorage.getItem('clientProfile')).data?JSON.parse(localStorage.getItem('clientProfile')).data:'';
  
  constructor(public userser : FrontserviceService, public router : Router) {
    this.clientid = this.clientData._id
    
    this.userser.getNewOpportunity(this.clientid).then((val:any)=>{
      if(val.success)
        this.newOpportunity = val.data
    })
   }

  ngOnInit() {
  }

  newopportunity(item:any):void{
    localStorage.setItem('overview',JSON.stringify(item));
    this.router.navigate(['/home/project-message']);
  }

  checkReadOpportunity(index:number){
    const i = this.newOpportunity[index]["influencers"].findIndex(d=> (d.influencerId === this.clientid));
    if( i !== -1 ){
      return this.newOpportunity[index]["influencers"][i]["read"]
    }else{
      return true
    }
  }

}
