import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ClientSnackbarComponent } from "../client-snackbar/client-snackbar.component";
import { FrontserviceService } from "../frontservice.service";

@Component({
  selector: "app-signup-client",
  templateUrl: "./signup-client.component.html",
  styleUrls: ["./signup-client.component.css"],
})
export class SignupClientComponent implements OnInit {
  client = {
    firstName: "",
    lastName: "",
    password: "",
    cpassword: "",
    email: "",
    companyName: "",
    contactNumber: "",
  };

  submitted: boolean = false;
  emailfield: boolean = false;
  userExist: boolean = false;
  inputfield: boolean = false;
  constructor(
    public userser: FrontserviceService,
    public route: Router,
    public snack: ClientSnackbarComponent
  ) {}

  ngOnInit() {}
  emailvalid(event: any) {
    var validRegex1 = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!this.client.email.match(validRegex1)) {
      // this.submitted =false;

      this.emailfield = true;
      return;
    } else {
      this.emailfield = false;
    }
  }

  inputvalid(event: any) {
    var validRegex = /^([0-9]\d*){6,15}$/g;
    if (!this.client.contactNumber.match(validRegex)) {
      // this.submitted = true;

      this.inputfield = true;
      return;
    } else {
      this.inputfield = false;
    }
  }

  submit(data: any) {
    this.submitted = true;
    var validRegex1 = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var validRegex = /^([0-9]\d*){6,15}$/g;
    if (
      !data.email ||
      !data.firstName ||
      !data.password ||
      !data.cpassword ||
      !data.companyName ||
      !data.contactNumber ||
      !data.contactNumber.match(validRegex) ||
      !data.email.match(validRegex1)
    ) {
      return;
    }
    // var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    // if(!this.client.email.match(validRegex) ){
    //   // this.submitted =false;

    //   this.emailfield =true;
    // return
    // }

    console.log(data, "sign up data");
    this.userser
      .clientadd(data)
      .then((val: any) => {
        // console.log(val,'Client Add')
        this.snack.snackbarSucces(
          val.message ? val.message : "Some Error Occured"
        );
        if (val.status == "success") {
          this.route.navigate(["/thankyou-influencer"]);
        }
      })
      .catch((err) => {
        console.log(err.error.message, "123");
        if ((this.userExist = true)) {
          this.snack.snackbarError(
            // (err.error.message = "Client already exists"
            //   ? err.error.message
            //   : "")
            (err.error.message?err.error.message : 'user alreaady exists')
          );
        }
  })
  }

  login() {
    this.route.navigate(["/login"]);
  }
}
