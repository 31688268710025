import { Component, OnInit } from '@angular/core';
import { FrontserviceService } from '../frontservice.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-live-campaign-listing',
  templateUrl: './live-campaign-listing.component.html',
  styleUrls: ['./live-campaign-listing.component.css']
})
export class LiveCampaignListingComponent implements OnInit {

  livecam:any [] = [];
  clientid: any;
  constructor(public userser : FrontserviceService, public router : Router) {
 let clientData:any =  JSON.parse(localStorage.getItem('clientProfile')).data?JSON.parse(localStorage.getItem('clientProfile')).data:'';

 this.clientid = clientData._id
 console.log(this.clientid,'clien33')
    let d = new Date();
    this.userser.livecampaign(this.clientid).then((val:any) => {
      console.log(val);
      this.livecam = val.data;
    })
   }

   overview(eve:any){
     this.userser.overview.push(eve)
     console.log(this.userser.overview,'dat878787')
    //  let overview:any= {
    //   agemax: eve.agemax,
    //         agemin
    //         : 
    //         eve.agemin,
    //         campaignName
    //         : 
    //         eve.campaignName,
    //         campaigndate
    //         : 
    //         eve.campaigndate,
    //         campaignoverview
    //         : 
    //         eve.campaignoverview,
    //         clientId
    //         : 
    //         eve.clientId,
    //         competitors
    //         : 
    //         eve.competitors,
        
    //         duration
    //         : 
    //         eve.duration,
    //         gender
    //         : 
    //         eve.gender,
            
    //         type
    //         : 
    //         eve.contenttype,
          
          
    //         managment
    //         : 
    //         false,
    //         relevanthashtagsphrases
    //         : 
    //         eve.relevanthashtagsphrases,
    //         socialplatforms
    //         : 
    //         eve.socialplatforms,
          
    //         _id
    //         : 
    //         eve._id
    //  }
     localStorage.setItem('overview',JSON.stringify(eve))

    // const data = { id: eve.socialplatforms, name: 'John' };

    this.router.navigate(['/campaign'])
   }

  ngOnInit() {
  }

}
