import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { FrontserviceService } from '../frontservice.service';
import { ThankyouPopupComponent } from '../thankyou-popup/thankyou-popup.component';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css']
})
export class ClientProfileComponent implements OnInit {

  userdata:any;
  firstName:any;
  lastName:any;
  email:any;
  contactNumber:any;
  socialAccounts:any
  socialArr:Array<String> = ["Facebook", "Instagram", "Tik-Tok", "YouTube"];
  value:Array<SocialAcc> = [{username:"", handle:""}];

  showval:boolean = false;

  updateuser:any;
constructor(public router : Router, public userser: FrontserviceService , public snack : ClientSnackbarComponent,
  private dialog : MatDialog ) {

 

}

ngOnInit() {
      this.userdata = JSON.parse(localStorage.getItem("clientProfile"));
      this.firstName = this.userdata.data.firstName;
      this.lastName = this.userdata.data.lastName;
      this.email = this.userdata.data.email;
      this.contactNumber = this.userdata.data.contactNumber;
      this.socialAccounts = this.userdata.data.socialAccounts;

      console.log(this.contactNumber,'Userrrrr')
}


updateProfile(){

this.showval = true
console.log(this.firstName,this.lastName,this.email);


console.log(this.userdata,'update user')
// return
this.userser.updateclient(this.userdata).then((val:any)=>{
  // this.snack.snackbarSucces("Updated Successfully")
  console.log(val,'client Update')



  // this.router.navigate(['/home/client-dashboard']);

})
let dialogref = this.dialog.open(ThankyouPopupComponent, {
    
  data: {
    
  },

}); 
// this.usersService.editUser({"firstName":this.firstName,"lastName":this.lastName},this.userdata.id).then(data => {
//   this.usersService.getUserDetails();
// })
}

goToHome(){
this.router.navigate(['/home/live-campaign']);
}

}
export interface SocialAcc{
  username:string;
  handle:string;
  
}
