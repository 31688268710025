import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../authentication.guard';
import { ClientfullComponent } from '../layouts/clientfull/clientfull.component';
import { AddCampaignComponent } from './add-campaign/add-campaign.component';
import { ArchivedCampaignsListingComponent } from './archived-campaigns-listing/archived-campaigns-listing.component';
import { ClientCampaignComponent } from './client-campaign/client-campaign.component';
import { ClientDashboardComponent } from './client-dashboard/client-dashboard.component';
import { ClientProfileComponent } from './client-profile/client-profile.component';
import { FrontDialogComponent } from './front-dialog/front-dialog.component';
import { HomeBlogComponent } from './home-blog/home-blog.component';
import { InfluencerDashboardComponent } from './influencer-dashboard/influencer-dashboard.component';
import { InfluencerNewOpportunitiesComponent } from './influencer-new-opportunities/influencer-new-opportunities.component';
import { InfluencerProfileComponent } from './influencer-profile/influencer-profile.component';
import { LiveCampaignListingComponent } from './live-campaign-listing/live-campaign-listing.component';
import { CampaignCalenderComponent } from './campaign-calender/campaign-calender.component';
import { CampaignFormComponent } from './campaign-form/campaign-form.component';
import { InfluencerCandidateShortComponent } from './influencer-candidate-short/influencer-candidate-short.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { PricingComponent } from './pricing/pricing.component';
import { SignupClientComponent } from './signup-client/signup-client.component';
import { ThankyouPopupComponent } from './thankyou-popup/thankyou-popup.component';
import { CampaignsOverviewComponent } from './campaigns-overview/campaigns-overview.component';
import { CandidateComponent } from './candidate/candidate.component';
import { ThankyouInfluencerComponent } from './thankyou-influencer/thankyou-influencer.component';
import { InfluencerCandidateListComponent } from './influencer-candidate-list/influencer-candidate-list.component';
import { ProfilePopupComponent } from './profile-popup/profile-popup.component';
import { CampaignApprovalComponent } from './campaign-approval/campaign-approval.component';
import { MessageComponent } from './message/message.component';
import { ReviewPostComponent } from './review-post/review-post.component';
import { IndexMessageComponent } from './index-message/index-message.component';
import { PostPreviewComponent } from './post-preview/post-preview.component';
import { StripeSuccessComponent } from './stripe-success/stripe-success.component';
import { StripeFailureComponent } from './stripe-failure/stripe-failure.component';
import { ShortlistPopupComponent } from './shortlist-popup/shortlist-popup.component';
import { OptionPopupComponent } from './option-popup/option-popup.component';
import { SubscriptionPopupComponent } from './subscription-popup/subscription-popup.component';
import { NotApprovedPopupComponent } from './not-approved-popup/not-approved-popup.component';
import { MessageSentPopupComponent } from './message-sent-popup/message-sent-popup.component';
import { NewProjectMessageComponent } from './new-project-message/new-project-message.component';
import { InfluencerMessageComponent } from './influencer-message/influencer-message.component';
import { ReviewPostPopupComponent } from './review-post-popup/review-post-popup.component';
import { CommissionPopupComponent } from './commission-popup/commission-popup.component';
import { ContactCloutPopupComponent } from './contact-clout-popup/contact-clout-popup.component';
import { ResponsePopupComponent } from './response-popup/response-popup.component';
import { ContentApprovalComponent } from './content-approval/content-approval.component';
import { MyPaymentComponent } from './my-payment/my-payment.component';
import { AboutComponent } from './about/about.component';
import { CloutPricingComponent } from './clout-pricing/clout-pricing.component';
import { CloutInfluencerComponent } from './clout-influencer/clout-influencer.component';
import { CloutContactComponent } from './clout-contact/clout-contact.component';
import { InfluencerFeedbackComponent } from './influencer-feedback/influencer-feedback.component';
import { FeedbackResponsePopupComponent } from './feedback-response-popup/feedback-response-popup.component';
import { SelectedInfluencersComponent } from '../selected-influencers/selected-influencers.component';
import { FreeSubscriptionComponent } from './free-subscription/free-subscription.component';
import { SelfSubscriptionComponent } from './self-subscription/self-subscription.component';
import { ManagedSubscriptionComponent } from './managed-subscription/managed-subscription.component';


export const FrontendRoutes: Routes = [
  {
        path: '',
        component: HomeBlogComponent,
  },

  {
    path: 'brief-campaign',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : CampaignFormComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },

  {
    path: 'live-campaign',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : LiveCampaignListingComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'campaign-calender',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : CampaignCalenderComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'new-opportunities',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : InfluencerNewOpportunitiesComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'project-message',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :NewProjectMessageComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },

  {
    path: 'archived-campaigns',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : ArchivedCampaignsListingComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },

  {
    path: 'profile',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : ClientProfileComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },

  {
    path: 'payment-details',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : PaymentDetailsComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },

  //Routing others

  //campaign routing
  {
    path: 'campaign',
    component: CandidateComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview',
      },
      {
        path: 'post-preview',
        component : PostPreviewComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'overview',
        component : CampaignsOverviewComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'shortlist',
        component : InfluencerCandidateShortComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'candidate-list',
        component : InfluencerCandidateListComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'review-post',
        component: ReviewPostComponent,
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'selected-influencers',
        component : SelectedInfluencersComponent,
        canActivate: [AuthenticationGuard]
      },
    ]
  },
  {
    path: 'sign-up-client',
    component: SignupClientComponent,
    // canActivate: [AuthenticationGuard]
  },
  {
    path: 'thankyou-influencer',
    component: ThankyouInfluencerComponent,
  },
  {
    path: 'client-dashboard',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : ClientDashboardComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'influencer-dashboard',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : InfluencerDashboardComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'client-campaign',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : ClientCampaignComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  
  {
    path: 'influencer-profile',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : InfluencerProfileComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'add-campaign',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : AddCampaignComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'front-dialog',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : FrontDialogComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'pricing',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : PricingComponent,
        canActivate: [AuthenticationGuard]
      }, 
      {
        path: 'stripe-failure',
        component : StripeFailureComponent,
        canActivate: [AuthenticationGuard]
      }, 
      {
        path: 'stripe-success',
        component : StripeSuccessComponent,
        canActivate: [AuthenticationGuard]
      }, 
    ]
  },
  
  
  
  
  {
    path: 'thankyou-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : ThankyouPopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  
  {
    path: 'profile-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : ProfilePopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'campaign-approval',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : CampaignApprovalComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  //  {
  //   path: 'campaign-approval',
  //   component: ClientfullComponent,
  //   children: [
  //     {
  //       path: '',
  //       component : CampaignApprovalComponent,
  //       canActivate: [AuthenticationGuard]
  //     }
  //   ]
  // },
  {
    path: 'campaign-message/:Id',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : MessageComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'index-message',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : IndexMessageComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'shortlist-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component : ShortlistPopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'option-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :OptionPopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'subscription-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :SubscriptionPopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'not-approved-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :NotApprovedPopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'message-sent-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :MessageSentPopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'influencer-message',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :InfluencerMessageComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'review-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :ReviewPostPopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'commission-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :CommissionPopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'contact-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :ContactCloutPopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'response-popup',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :ResponsePopupComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'content-approval',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :ContentApprovalComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  // content-approval routing starts
  {
    path: 'content-approval',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'influencer-feedback',
      },
      {
        path: 'influencer-feedback',
        component : InfluencerFeedbackComponent,
        canActivate: [AuthenticationGuard]
      },
      // {
      //   path: 'feeedback-response',
      //   component : FeedbackResponsePopupComponent,
      //   canActivate: [AuthenticationGuard]
      // },
    ]
  },
// content-approval routing ends
{
  path: 'feedback-response',
  component: ClientfullComponent,
  children: [
    {
      path: '',
      component :FeedbackResponsePopupComponent,
      canActivate: [AuthenticationGuard]
    }
  ]
},
  {
    path: 'influencer-my-payment',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :MyPaymentComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'about',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :AboutComponent,
        // canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'clout-pricing',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :CloutPricingComponent,
        // canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'clout-influencer',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :CloutInfluencerComponent,
        // canActivate: [AuthenticationGuard]
      }
    ]
  },
  {
    path: 'clout-contact',
    component: ClientfullComponent,
    children: [
      {
        path: '',
        component :CloutContactComponent,
        // canActivate: [AuthenticationGuard]
      }
    ]
  },

  {
    path: 'free-subscription',
    component: FreeSubscriptionComponent,
    children: [
      {
        path: '',
        component :FreeSubscriptionComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },

  {
    path: 'self-subscription',
    component: SelfSubscriptionComponent,
    children: [
      {
        path: '',
        component :SelfSubscriptionComponent,
        canActivate: [AuthenticationGuard]
      }
    ]
  },

  {
    path: 'managed-subscription',
    component: ManagedSubscriptionComponent,
    children: [
      {
        path: '',
        component :ManagedSubscriptionComponent,
        canActivate: [AuthenticationGuard]
      }
    ] 
  },


  // {
  //   path: 'home-blog',
  //   component: ClientfullComponent,
  //   children: [
  //     {
  //       path: '',
  //       component : HomeBlogComponent,
  //       canActivate: [AuthenticationGuard]
  //     }
  //   ]
  // },
];

// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class FrontendRoutingModule { }
