import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FrontserviceService } from "../frontservice.service";
import { ShortlistedPopupComponent } from '../shortlisted-popup/shortlisted-popup.component';

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.css']
})
export class ProfilePopupComponent implements OnInit {
  onAdd = new EventEmitter();
  overViewData:any = JSON.parse(localStorage.getItem("overview"));
  type:string = this.overViewData.socialplatforms.toLowerCase().replace("-", "");
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog : MatDialog,
  public userser: FrontserviceService,
  ) {}
  
  onButtonClick(d:any) {
    this.data.isShortList = true;
    this.onAdd.emit(d);
    
    this.dialog.open(ShortlistedPopupComponent,{
      panelClass: 'shortlisted-box'
    })
 
  }

  ngOnInit() {
  }

  cancel(){
    this.dialog.closeAll();
  }
  addClick(inf:any){
    inf['click']= true
  }
  addCommissioned(influencer: any):void {
      let obj:any = {
          influencerDetail: influencer.influencerDetail,
          price: influencer.price,
      };
      delete influencer.click
      this.onAdd.emit(obj);
  }
}
