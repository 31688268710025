import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thankyou-popup',
  templateUrl: './thankyou-popup.component.html',
  styleUrls: ['./thankyou-popup.component.css']
})
export class ThankyouPopupComponent implements OnInit {

  constructor(public route : Router , private dialog : MatDialog) { }

  ngOnInit() {
  }

  cancel(){
    // this.route.navigate(['/home/live-campaign'])
    this.dialog.closeAll()
    }
}
