import { Component, OnInit } from '@angular/core';
import { FrontserviceService } from '../frontservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-review-post',
  templateUrl: './review-post.component.html',
  styleUrls: ['./review-post.component.css']
})
export class ReviewPostComponent implements OnInit {

  campaignData: any = localStorage.getItem('overview') ? JSON.parse(localStorage.getItem('overview')) : undefined;
  items:any[] = [];
  constructor(private userser: FrontserviceService,
    private router: Router) {
    this.userser.compaignPosts(this.campaignData._id).then((res: any) => {
      if(res.success){
        this.items = [];
        res.data.influencers.forEach((d:any)=>{
          d.approved = 0;
          d.pending = 0;
          
          if(d.price){
            if(d.postId && d.postId.postData){
              d.postId.postData.forEach(e=>{
                if(e.status == 'APPROVED'){
                  d.approved++;
                }
                else if(e.status == 'PENDING'){
                  d.pending++;
                }
              })
            }
            this.items.push(d)
          }
        })
      }
      
    })
   }

  ngOnInit() {
  }
  gotToDetailPost(iID:string) {
    localStorage.setItem('postInfluencerId', iID);
    this.router.navigate(['/campaign/post-preview'])
  }

}
