import { Routes } from '@angular/router';
import { LoginClientComponent } from './admin/login-client/login-client.component';
import { SignupInfluencerComponent } from './frontend/signup-influencer/signup-influencer.component';
import { CloutInfluencerComponent } from './frontend/clout-influencer/clout-influencer.component';

export const Approutes: Routes = [
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './frontend/frontend.module#FrontendModule',
  },
  {
    path: 'login',
    component: LoginClientComponent,
    // canActivate: [AuthenticationGuard]
  },
  {
    path: 'sign-up-influencer',
    component: SignupInfluencerComponent,
    // canActivate: [AuthenticationGuard]
  },
  // {
  //   path: 'clout-influencer',
  //   component :CloutInfluencerComponent,
  //   // canActivate: [AuthenticationGuard]
  // }
  // {
  //   path: '**',
  //   redirectTo: '/admin',
  //    pathMatch : 'full'
  // },
];
