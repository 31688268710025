import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonModuleRoutingModule } from './common-module-routing.module';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { NavigationComponent } from '../shared/header-navigation/navigation.component';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule, MatButtonModule, MatProgressBarModule, MatIconModule, MatFormFieldModule, MatInputModule, MatRippleModule, MatTooltipModule, MatTableModule, MatPaginatorModule, MatRadioModule, MatSelectModule, MatListModule, MatStepperModule, MatCardModule, MatToolbarModule, MatDatepickerModule, MatSidenavModule, MatSlideToggleModule, MatGridListModule, MatSnackBarModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FrontendRoutes } from '../frontend/frontend-routing.module';
import { ClientfullComponent } from '../layouts/clientfull/clientfull.component';
import { FullComponent } from '../layouts/full/full.component';
import { FooterComponent } from '../shared/footer/footer.component';

@NgModule({
  declarations: [
      BreadcrumbComponent,
      SidebarComponent,
      ClientfullComponent,
      FullComponent,
      NavigationComponent
  ],
  providers:[ NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,],
  imports: [
    CommonModule,
    CommonModuleRoutingModule,
   
      FormsModule,
      ReactiveFormsModule,
      NgbModule,
      // RouterModule.forChild(FrontendRoutes),
      MatSliderModule,
      MatButtonModule,
      MatProgressBarModule,
      MatIconModule,
      MatFormFieldModule,
      MatInputModule,
      MatRippleModule,
      MatTooltipModule,
      MatTableModule,
      MatPaginatorModule,
      MatRadioModule,
      MatSelectModule,
      MatListModule,
      MatStepperModule,
      MatCardModule,
      PerfectScrollbarModule,
      LayoutModule,
      MatToolbarModule,
      MatDatepickerModule,
      MatSidenavModule,
      MatSlideToggleModule,
      MatMomentDateModule,
      MatGridListModule,
      MatSnackBarModule,
    
    ],
  
})
export class CommonModuleModule { }
