import { Component, OnInit } from '@angular/core';
import { CheckoutService } from '../../services/checkout.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stripe-failure',
  templateUrl: './stripe-failure.component.html',
  styleUrls: ['./stripe-failure.component.css']
})
export class StripeFailureComponent implements OnInit {

  stripeSessionId:string = localStorage.getItem('stripeSessionId');
  constructor(private checkout: CheckoutService, public route:Router) { 
    this.checkout.updateSubscription(this.stripeSessionId);
  }

  ngOnInit() {
    this.paymentFailed()
  }
  
  ngOnDestroy() {
    localStorage.removeItem('stripeSessionId');
  }

  paymentFailed() {
    this.route.navigate(['/pricing'])
  }

}
