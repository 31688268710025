// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD0Do9W_7I1dXiWE7lcxuHLb8D28IP8yBw",
  authDomain: "realstate-30366.firebaseapp.com",
  databaseURL: "https://realstate-30366.firebaseio.com",
  projectId: "realstate-30366",
  storageBucket: "realstate-30366.appspot.com",
  messagingSenderId: "79731000339",
  appId: "1:79731000339:web:3fddb43d13d33e46bfabe6",
  measurementId: "G-78QM86WGSW"
  },
  aws:{
   accessKeyId: 'AKIAX7VCYNZU77Q37QP4',
   secretAccessKey: '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',
   region: 'eu-west-2',
 }

  // firebase: {
  //   apiKey: "AIzaSyDPNI7n3qKHFZGwZ_pYz3tls8B1Py7OVe8",
  //   authDomain: "juristway.firebaseapp.com",
  //   databaseURL: "https://juristway.firebaseio.com",
  //   projectId: "juristway",
  //   storageBucket: "juristway.appspot.com",
  //   messagingSenderId: "722795618273",
  //   appId: "1:722795618273:web:b900b539ddf2e80d"
  // } 
};
