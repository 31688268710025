import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FrontserviceService } from "../frontservice.service";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  messageId: any
  message: any
  replyMessage: any
  userdata:any
  constructor(public route: Router,
    public FrontserviceService: FrontserviceService,
    private activatedroute: ActivatedRoute,
  ) {
    this.userdata = JSON.parse(localStorage.getItem("clientProfile"));
    console.log(this.userdata?.data?._id,'this.userdata')

    this.messageId = this.activatedroute.snapshot.params.Id;
    console.log(this.messageId, 'this.messageId')


    this.getmesage()

  }
  getmesage() {
    this.FrontserviceService.getmessagebyid(this.messageId).then((res: any) => {
      if (res.success) {
        this.message = res.data
        if (!res.data.read) {
          this.FrontserviceService.readMessage(this.messageId).then((res: any) => {

          })
        }
      }

    })
  }

  ngOnInit() {
  }
  backtomsg() {
    this.route.navigate(['/home/index-message'])
  }
  reply() {
    if (this.messageId) {


      let data = {
        "clientId": this.message?.clientId,
        "influncerId": this.message?.influncerId,
        "campaignId": this.message?.campaignId,
        "createdBy": this.userdata?.data?._id,
        "message": this.replyMessage,
        "title": this.message?.title,
        "parent": this.message?._id
      }
      this.FrontserviceService.sendReply(data).then((res: any) => {
        this.replyMessage=''
        if(res?.data?._id){

          this.route.navigate(["campaign-message/"+res?.data?._id])
          this.messageId = res?.data?._id

          this.getmesage();
        }
        // this.getmesage()

      })
    }

  }
}
