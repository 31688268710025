import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shortlist-popup',
  templateUrl: './shortlist-popup.component.html',
  styleUrls: ['./shortlist-popup.component.css']
})
export class ShortlistPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
 

}
