import { Component, OnInit } from '@angular/core';
import { Campaign } from "./campaign-model";
import { MatDialog } from '@angular/material';
import{} from '@angular/router';
import { FileService } from '../file.service';
import { FileUpload } from '../fileupload-client';
import { Router } from '@angular/router';
import { FrontserviceService } from '../frontservice.service';
import { ThankyouPopupComponent } from '../thankyou-popup/thankyou-popup.component';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { ContactCloutPopupComponent } from '../contact-clout-popup/contact-clout-popup.component';

@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.css']
})
export class CampaignFormComponent implements OnInit {

  model:Campaign = {
    campaignName: '',
    gender: '',
    location: [],
    age: '',
    interest: [],
    socialplatforms: '',
    contenttype: '',
    duration: 0,
    supportingdocuments: '',
    campaignoverview: '',
    competitors: '',
    relevanthashtagsphrases: '',
    management: null,
    campaigndate: '',
    clientId: JSON.parse(localStorage.getItem('clientProfile')).data._id
  };
  form_num:number = 1;
  interestData: any[] = [];
  inputfield : boolean= false;
  currentUpload: any = FileUpload;
	selectedFiles: any = FileList;
  country_lis: any[] = [];

  countryCtrl: any='';
  filteredCountry: any;
  submitted:boolean=false;
  submitted1:boolean=false;
  submitted2:boolean=false;
  constructor(private userser:FrontserviceService, private snack: ClientSnackbarComponent, public route : Router , private dialog : MatDialog, private FileUploadService: FileService) { 
    this.userser.getInterestData().then((val:any)=>{
      this.interestData = val.data.interests || []
    })
    
  }
  // inputvalid(event:any){
  //   // var validRegex = /^[a-zA-Z0-9]$/g;
  //   var validRegex = /^(?:[a-zA-Z0-9\s]+)?$/
  //   if(!this.model.campaignName.match(validRegex) ){
  //     this.submitted =true;

  //     this.inputfield =true;
  //   return
  //   }
  //   else{
  //     this.inputfield = false;
  //   }
  // }
  save1(){
    // var validRegex = /^(?:[a-zA-Z0-9\s]+)?$/
    if(!this.model.socialplatforms || !this.model.campaignName ){
      this.submitted1=true;
      return ;
    }
    else{
      return this.form_num=2;
    }
  }

  continue(){
    
    if(!this.model.gender || this.model.age.length == 0  || this.model.location.length == 0 || this.model.interest.length == 0  ){
      this.submitted2=true;
      return ;
    }else{
      return this.form_num=3;
    }
  }
  
  changeLocation(val:any){
    this.model.location.push(val);
  }

  
 filtercountry() {
  
  this.userser.getLocationData(this.countryCtrl).then((data:any) => {
    
    if(data.success){
      this.country_lis = data.data.locations;
      this.filteredCountry = this.countryCtrl ? (this.country_lis.length ? this.country_lis : [{ name: 'No Item found', code: 'null' }]) : this.country_lis.slice();

    }else{
      
      this.filteredCountry = this.countryCtrl ? [{ name: 'No Item found', code: 'null' }] : this.country_lis.slice();
    }
    
    
  }).catch((err) => {
    console.log(err);
    this.filteredCountry = this.countryCtrl ? [{ name: 'No Item found', code: 'null' }] : this.country_lis.slice();
  })
  }

  save(){
    let newModel:any = {...this.model}
    if(!newModel.campaignName || !newModel.gender || !newModel.age || !newModel.socialplatforms || !newModel.contenttype || !newModel.duration || !newModel.campaignoverview || newModel.management == null || !newModel.campaigndate){
      this.submitted=true;
      // this.snack.snackbarSucces("Check Inputs...")

      return
    }

    if(newModel.campaigndate && newModel.duration){
      let endDate = new Date(newModel.campaigndate);
      endDate.setDate(endDate.getDate() + newModel.duration * 7);
      newModel['campaignEndDate'] = (new Date(endDate).toISOString()).split('T')[0];
    }
    
    this.userser.addcampaign(newModel).then((val:any)=>{
    
      console.log(val,'add campaign')
      if(val.status == 'success' && this.model.management == true){
        // this.snack.snackbarSucces("Added Successfully...")
        const dialogRef = this.dialog.open(ContactCloutPopupComponent, {
          panelClass:['Contact-clout-popup-box']
        });
        dialogRef.afterClosed().subscribe(() => {
          this.route.navigate(['home/live-campaign'])
        });
        return
      }
      // this.snack.snackbarSucces("Some Error Occured, Check Inputs...")
    })
   

  }
  setClout(d:boolean){
   this.model.management = d
  }
  setDuration(d:number){
   this.model.duration = d
  }

  contactcloutpopup(){
    console.log(this.model.management,"mange")
    if(this.model.management == null){
      this.submitted=true
    }else{
      this.save()
    }
    
    
  }

  ngOnInit() {
    // this.userser.showLoading()
    this.userser.getLocationData('').then((data:any) => {
      if(data.success)
      this.country_lis = data.data.locations
      
    })
  }



  onFileInput(files: FileList) {
		if (files.item(0).size < 5000000) {
			let file = files.item(0);
			this.currentUpload = new FileUpload(file);
			this.uploadFile();
		} else {
			// this.toast.toastError(
			//   'Allowed image format is jpeg,jpg or png with a max size 5 MB'
			// );
			// this.toastController.presentToast("Allowed image format is jpeg,jpg or png with a max size 5 MB")
			files = null;
		}
    
	}

  uploadFile() {
		this.FileUploadService.pushUpload(this.currentUpload, '/campaign-data/')
    .then((res: any) => {
      if(res.url){
        this.model.supportingdocuments = res.url;
      }
		});
	}

}

