import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FrontserviceService } from '../frontservice.service';

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.css']
})
export class CandidateComponent implements OnInit {
  overview: any;
  act: any;
  userData:any = JSON.parse(localStorage.getItem('clientProfile'));
  campaignData:any = localStorage.getItem('overview') ? JSON.parse(localStorage.getItem('overview')) : {};
  constructor(private route: Router, public useruser:FrontserviceService,public router : Router) {
  let x:any = localStorage.getItem('overview')
  let newarr:any = JSON.parse(x)
  this.overview = newarr
  console.log(this.overview,'da443')
  route.events.subscribe((val)=>{
    // console.log(val);
    this.act = this.router.url;
    })
   }

  ngOnInit() {
    // console.log('call',this.campaignData)
    if(this.campaignData._id){
      this.useruser.updateCampaignStorage(this.campaignData._id);

   
  }
    // this._route.queryParams.subscribe(params => {
    //   console.log(params,'pa323')

    // })
  //   if(this.router.url == '/campaign/overview' || this.router.url == '/home/client-campaign' || this.router.url == '/campaign/shortlist' || 
  //   this.router.url == '/campaign/candidate-list' || this.router.url == '/home/payment-details' || this.router.url == '/home/live-campaign' || this.router.url == '/home/brief-campaign'){
  //     console.log("this.router.url", this.router.url);
      
  //     this.act = this.router.url;
  // }
  }
  onActivate(event:any) {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
}
  backto(){
     this.route.navigate(['/home/live-campaign'])
  }
}
