import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../core/constant';
import { LoadingService } from "../services/loading/loading.service";

@Injectable({
  providedIn: 'root'
})
export class FrontserviceService {
	overview:any[]=[]

  url: any = 'https://influencer.stageprojects.xyz'
	// url: any = 'http://localhost:4002'
	apiUrl: string = AppSettings.API_ENDPOINT;
	loginData: any;
	headers: any;
	accessToken: any;
	userId: any;

  constructor(public http: HttpClient, private loading: LoadingService) {
    this.headers = new Headers();

		this.headers.append('Access-Control-Allow-Origin', '*');
		this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
		this.headers.append('Accept', 'application/json');
		this.headers.append('content-type', 'application/json');
   }

   showLoading(){
		this.loading.showLoading();
   }

   hideLoading(){
		this.loading.hideLoading();
   }

   	async updateCampaignStorage(id: string){
		await this.getOverview(id).then((res:any)=>{
			localStorage.setItem('overview',JSON.stringify(res.data))
		})
	}

   clientlogin(data){
		let loginurl = this.url + '/client/login';
		console.log(data,loginurl);
		return new Promise((resolve, reject) => {
		  this.http.post(loginurl, data).subscribe(
			(res: any) => {
			  console.log(res);
			  resolve(res);
			},
			(err) => {
			  reject(err);
			  console.log(err);
			}
		  );
		});
	  }

   getInterestData(){
		let url:any = this.url + '/influencer/interests'
		return new Promise((resolve, reject) => {
		  this.http.get(url).subscribe(
			(res: any) => {
			  resolve(res);
			},
			(err) => {
			  reject(err);
			  console.log(err);
			}
		  );
		});
	}

   getLocationData(searchQuery:string){
		let url:any = this.url + '/influencer/locations?query='+searchQuery;
		return new Promise((resolve, reject) => {
		  this.http.get(url).subscribe(
			(res: any) => {
			  resolve(res);
			},
			(err) => {
			  reject(err);
			  console.log(err);
			}
		  );
		});
	  }


    clientadd(data){
      let addurl = this.url + '/client/add';
      console.log(data,addurl);
      return new Promise((resolve, reject) => {
        this.http.post(addurl, data).subscribe(
        (res: any) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          reject(err);
          console.log(err);
        }
        );
      });
      }

	  influencerAdd(data){
      let addurl = this.url + '/influencer/add';
      console.log(data,addurl);
      return new Promise((resolve, reject) => {
        this.http.post(addurl, data).subscribe(
        (res: any) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          reject(err);
          console.log(err);
        }
        );
      });
      }

	  updateclient(data) {
		let edit = this.url + '/client/update';
		console.log(edit);
		return new Promise((resolve, reject) => {
			this.http.patch(edit, data).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}
	
	shortlist(data,overviewid:any) {
		console.log(overviewid,'data776')
		let edit = this.url + '/campaign/influencers-add/'+overviewid;
		console.log(edit);
		return new Promise((resolve, reject) => {
			this.http.put(edit, data).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	readOpportunity(campaignId:string,influencerId:string) {
		let edit = this.url + `/campaign/influencers-read/${campaignId}/${influencerId}`;
		return new Promise((resolve, reject) => {
			this.http.put(edit, {}).subscribe((res: any) => {
				resolve(res);
			},
			(err) => {
				reject(err);
				console.log(err);
			}
		);
	});
}
	comissioned(data,overviewid:any) {
		console.log(overviewid,'data776')
		let edit = this.url + '/campaign/influencers-commission/'+overviewid;
		console.log(edit);
		return new Promise((resolve, reject) => {
			this.http.put(edit, data).subscribe(
				(res: any) => {
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	respondOpportunity(campaignId:string,influencerId:string,status:string) {
		let edit = this.url + `/campaign/influencers-status/${campaignId}/${influencerId}/${status}`;
		return new Promise((resolve, reject) => {
			this.http.put(edit, {}).subscribe(
				(res: any) => {
					resolve(res);
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	  shortlistpriceupdate(data,overviewid:any) {
		console.log(overviewid,'data776')
		let edit = this.url + '/campaign/influencers-add/'+overviewid;
		console.log(edit);
		return new Promise((resolve, reject) => {
			this.http.patch(edit, data).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}
	


	// Campaign API

	getcampaign() {
		// let filter = encodeURIComponent(JSON.stringify(data));
		let getall = this.url + '/campaign/get';
		console.log(getall);
		return new Promise((resolve, reject) => {
			this.http.get(getall).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	getNewOpportunity(clientid:any) {
		
		let get = this.url + `/campaign/new-opportunities/${clientid}`;
		console.log(get);
		return new Promise((resolve, reject) => {
			this.http.get(get).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	addcampaign(data) {
		let add = this.url + '/campaign/add';
		console.log(add,data,'url');
		delete data._id
		return new Promise((resolve, reject) => {
			console.log(data,'dddddd')
			this.http.post(add, data).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	editcampaign(data) {
		let edit = this.url + '/campaign/update';
		console.log(edit);
		return new Promise((resolve, reject) => {
			this.http.patch(edit, data).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	deletecampaign(data: any) {
		let deleteinf = this.url + `/campaign/delete/${data}`;
		console.log(deleteinf);
		return new Promise((resolve, reject) => {
			this.http.delete(deleteinf, data,).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	getclientcampaign(data: any) {
		let deleteinf = this.url + `/clientcampaign/get/${data}`;
		console.log(deleteinf);
		return new Promise((resolve, reject) => {
			this.http.get(deleteinf, data,).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}
	getshortlist(data: any) {
		let deleteinf = this.url + `/campaign/get/`+data;
		console.log(deleteinf);
		return new Promise((resolve, reject) => {
			this.http.get(deleteinf, data,).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	pastcampaign(clientid:any) {
		let past = this.url + `/archived/campaign/${clientid}`;
		return new Promise((resolve, reject) => {
			this.http.get(past).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	livecampaign(client:any) {
		let past = this.url + `/live/campaign/${client}`;
		console.log(past,'da3223');
		return new Promise((resolve, reject) => {
			this.http.get(past).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}



	
	candidatelist(data: any) {
		console.log('data878',data)
		let past = this.url + `/influencer/get`;
		console.log(past,'da3223');
		return new Promise((resolve, reject) => {
			this.http.post(past, data).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	candidateFetch(type: String, filter: Object = null) {
		let past = this.url + `/influencer/fetch/${type}`;
		return new Promise((resolve, reject) => {
			this.http.post(past, filter).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}

	candidatelistInsta(limit: Number, query: String) {
		let past = this.url + `/get-influencer-instagram/${limit}/${query}`;
		return new Promise((resolve, reject) => {
			this.http.get(past).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}
	candidateDataGet(type:string,userId: string) {
		let past = this.url + `/influencer/get-user-detail/${type}/${userId}`;
		return new Promise((resolve, reject) => {
			this.http.get(past).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}
	getOverview(id:any){
		let url:any = this.url + '/campaign/get/' + id
		return new Promise((resolve, reject) => {
		  this.http.get(url).subscribe(
			(res: any) => {
			  resolve(res);
			},
			(err) => {
			  reject(err);
			  console.log(err);
			}
		  );
		});
	}
	savePost(data){
		let addurl = this.url + '/post/add';
		console.log(data,addurl);
		return new Promise((resolve, reject) => {
		  this.http.post(addurl, data).subscribe(
		  (res: any) => {
			console.log(res);
			resolve(res);
		  },
		  (err) => {
			reject(err);
			console.log(err);
		  }
		  );
		});
		}
		compaignPosts(id:any){
			let url:any = this.url + '/campaign/get-post/' + id
			return new Promise((resolve, reject) => {
			  this.http.get(url).subscribe(
				(res: any) => {
				  resolve(res);
				},
				(err) => {
				  reject(err);
				  console.log(err);
				}
			  );
			});
		}
		
		getmessage(id){
			let url:any = this.url + '/message/fetch/' + id
			return new Promise((resolve, reject) => {
			  this.http.get(url).subscribe(
				(res: any) => {
				  resolve(res);
				},
				(err) => {
				  reject(err);
				  console.log(err);
				}
			  );
			});
		}
		readMessage(id){
			let edit = this.url + '/message/read/'+id;
		console.log(edit);
		return new Promise((resolve, reject) => {
			this.http.patch(edit, {}).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
		}
		getmessagebyid(id){
			let url:any = this.url + '/message/get/' + id
			return new Promise((resolve, reject) => {
			  this.http.get(url).subscribe(
				(res: any) => {
				  resolve(res);
				},
				(err) => {
				  reject(err);
				  console.log(err);
				}
			  );
			});
		}
		sendReply(data){
			let reply = this.url + '/message/add';
		console.log(data,reply);
		return new Promise((resolve, reject) => {
		  this.http.post(reply, data).subscribe(
			(res: any) => {
			  console.log(res);
			  resolve(res);
			},
			(err) => {
			  reject(err);
			  console.log(err);
			}
		  );
		});


		}
		
}
