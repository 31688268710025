import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FeedbackResponsePopupComponent } from '../feedback-response-popup/feedback-response-popup.component';

@Component({
  selector: 'app-influencer-message',
  templateUrl: './influencer-message.component.html',
  styleUrls: ['./influencer-message.component.css']
})
export class InfluencerMessageComponent implements OnInit {

   message: any;
    constructor(private dialog : MatDialog, public route : Router ) { }
  backtomsg(){
    this.route.navigate(['/home/index-message'])
  }
  notapppopup(){
    if (this.message.trim().length === 0){
      return;
    }
    {
      this.dialog.open(FeedbackResponsePopupComponent,{
        panelClass:['feedebackresponsemessage-box'],
      }) 
    }
  }

  ngOnInit() {
  }

}
