import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ResponsePopupComponent } from '../response-popup/response-popup.component';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { FrontserviceService } from "../frontservice.service";

@Component({
  selector: 'app-new-project-message',
  templateUrl: './new-project-message.component.html',
  styleUrls: ['./new-project-message.component.css']
})
export class NewProjectMessageComponent implements OnInit {

  campaignData:any = localStorage.getItem('overview') ? JSON.parse(localStorage.getItem('overview')) : {};
  clientData:any =  localStorage.getItem('clientProfile') ? JSON.parse(localStorage.getItem('clientProfile')).data : {};
  clientid:string = this.clientData._id ? this.clientData._id : '';
  response:string = 'pending';
  buttonDisabled = false;
  constructor( public router :Router, private dialog : MatDialog, private snack: ClientSnackbarComponent, private frontendSer: FrontserviceService ) { }

  ngOnInit() {
    this.frontendSer.readOpportunity(this.campaignData._id, this.clientid);
    const i = this.campaignData["influencers"].findIndex(d=> (d.influencerId === this.clientid));
    if( i !== -1 ){
      this.response =  this.campaignData["influencers"][i]["status"]
      if(this.campaignData["influencers"][i]["status"] === 'approved' || this.campaignData["influencers"][i]["status"] === 'rejected'){
        this.buttonDisabled = true;
      }
    }
  }

  changeResponse(value:boolean):void {
    if(value){
      this.response = 'approved'; 
    }else{
      this.response = 'rejected'; 
    }
  }

  responseFun(){

    
    if(this.response && this.response === 'pending'){
      this.snack.snackbarSucces("Check Inputs...")
    }else{
      this.buttonDisabled = true;
      this.frontendSer.respondOpportunity(this.campaignData._id, this.clientid, this.response).then((response:any) =>{
        if(response && response.success){
          
          const dialogref = this.dialog.open(ResponsePopupComponent, {
            panelClass: 'response-box'
          });
        }
      })
    } 
  }
  
}
