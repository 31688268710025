import { Component, OnInit } from "@angular/core";
import { AppSettings } from "../../core/constant";
import { UsersService } from "../../services/users/users.service";
import { Router } from "@angular/router";
import { SnackComponent } from "../snack/snack.component";

@Component({
  selector: "app-login-client",
  templateUrl: "./login-client.component.html",
  styleUrls: ["./login-client.component.css"],
})
export class LoginClientComponent implements OnInit {
  hide = true;
  site_name: string;
  loginFormModel: any;
  dataset: any = "Admin";
  invalidData: boolean = false;
  submitted: boolean = false;
  constructor(public usersService: UsersService, public router: Router,public snack : SnackComponent) {
    this.site_name = AppSettings.SITE_NAME;

    this.loginFormModel = {
      email: "",
      password: "",
      // "realm" : "admin"
    };
  }

  ngOnInit() {}
  start(data:any){
    if(!this.loginFormModel.email || !this.loginFormModel.password){
      this.submitted=true;
      return;
    }else{
      this.usersService.loginuser(data).then((data: any) => {
        if (data) {
          localStorage.setItem("user", data.data.role);
          localStorage.setItem("clientProfile", JSON.stringify(data));
          if (data.data.role == "CLIENT") {
            // this.route.navigate(['home/brief-campaign', {replaceUrl:true}])
            setTimeout(() => {
              this.router.navigate(["home/brief-campaign"], {
                replaceUrl: true,
              });
            });
          } else if (data.data.role == "INFLUENCER") {
            setTimeout(() => {
              this.router.navigate(["home/new-opportunities"], {
                replaceUrl: true,
              });
            });
          }
        }
        // this.goToAdmin();
      }).catch((err) => {
        console.log(err.error.message,'1234');
        
        if (this.invalidData=true){
          this.snack.snackbarError(err.error.message?err.error.message:"Invalid email or password")
        }
      })
    }
  }

  // doLogin(data: any) {
  //   console.log(data, "dataaaaaa");
  //   if (data.email != "" && data.password != "") {
  //     this.usersService.loginuser(data).then((data: any) => {
  //       if (data) {
  //         localStorage.setItem("user", data.data.role);
  //         localStorage.setItem("clientProfile", JSON.stringify(data));
  //         if (data.data.role == "CLIENT") {
  //           // this.route.navigate(['home/brief-campaign', {replaceUrl:true}])
  //           setTimeout(() => {
  //             this.router.navigate(["home/brief-campaign"], {
  //               replaceUrl: true,
  //             });
  //           });
  //         } else if (data.data.role == "INFLUENCER") {
  //           setTimeout(() => {
  //             this.router.navigate(["home/new-opportunities"], {
  //               replaceUrl: true,
  //             });
  //           });
  //         }
  //       }
  //       // this.goToAdmin();
  //     }).catch((err) => {
  //       console.log(err.error.message,'1234');
        
  //       if (this.invalidData=true){
  //         this.snack.snackbarError(err.error.message?err.error.message:"Invalid email or password")
  //       }
  //     })
  //   }
  //   //   this.usersService.getUserDetails().then(dataDetail => {
  //   // })
  // }
  gosign() {
    this.router.navigate(["/home/sign-up-client"]);
  }
 
}
