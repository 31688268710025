import { Component, OnInit } from '@angular/core';
import { FrontserviceService } from '../frontservice.service';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.css']
})
export class ClientDashboardComponent implements OnInit {

  campaign:any
  userId:any

  constructor(public userser : FrontserviceService) { 
    this.userId = JSON.parse(localStorage.getItem('clientProfile'))
    this.getall();
  }

  ngOnInit() {
  }

  getall(){
    this.userser.getclientcampaign(this.userId.data._id).then((val:any)=>{
      console.log(val,'getting campaign')

      this.campaign = val.length
    })
  }

}
