import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { FrontserviceService } from '../frontservice.service';

@Component({
  selector: 'app-influencer-profile',
  templateUrl: './influencer-profile.component.html',
  styleUrls: ['./influencer-profile.component.css']
})
export class InfluencerProfileComponent implements OnInit {

 
  userdata:any;
  firstName:any;
  lastName:any;
  email:any;
  password:any;

  showval:boolean = false;

  updateuser:any;
constructor(public router : Router, public userser: FrontserviceService , public snack: ClientSnackbarComponent ) {



}

ngOnInit() {
      this.userdata = JSON.parse(localStorage.getItem("clientProfile"));
      console.log(this.userdata.data,'Userrrrr')
      this.firstName = this.userdata.data.firstName;
      this.lastName = this.userdata.data.lastName;
      this.email = this.userdata.data.email;
}

updateProfile(){
this.showval = true
console.log(this.firstName,this.lastName,this.email);
this.updateuser = {
  firstName:this.firstName,
  lastName:this.lastName,
  email:this.email,
  password:this.password,
  _id:this.userdata.data._id
}
console.log(this.updateuser,'update user')
this.userser.updateclient(this.updateuser).then((val:any)=>{
  this.snack.snackbarSucces("Updated Successfully")
  console.log(val,'Influencer Update')
  this.router.navigate(['/home/influencer-dashboard']);
})
// this.usersService.editUser({"firstName":this.firstName,"lastName":this.lastName},this.userdata.id).then(data => {
//   this.usersService.getUserDetails();
// })
}

goToHome(){
this.router.navigate(['/home/influencer-dashboard']);
}

}
