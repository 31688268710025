import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-option-popup',
  templateUrl: './option-popup.component.html',
  styleUrls: ['./option-popup.component.css']
})
export class OptionPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
