import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NotApprovedPopupComponent } from '../not-approved-popup/not-approved-popup.component';
@Component({
  selector: 'app-message-sent-popup',
  templateUrl: './message-sent-popup.component.html',
  styleUrls: ['./message-sent-popup.component.css']
})
export class MessageSentPopupComponent implements OnInit {
  constructor(public route : Router , private dialog : MatDialog) { }
  
  commissionpopup(){
    {
      this.dialog.open(NotApprovedPopupComponent,{
        panelClass:['Reviewpopup-box'],
      })
    }
  }

  ngOnInit() {
  }
cancel(){
  this.dialog.closeAll()
}
}
