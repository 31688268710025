import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../core/constant';
import { LoadingService } from '../loading/loading.service';
import { SnackbarComponent } from '../../shared/snackbar/snackbar.component';

import { DialogComponent  } from '../../shared/dialog/dialog.component';

@Injectable()
export class UsersService {

	url: any = 'https://influencer.stageprojects.xyz'
	// url: any = 'http://localhost:4002'
  	apiUrl : string = AppSettings.API_ENDPOINT;
	loginData: any;
	headers : any;
	accessToken : any;
	userId : any;
  constructor(
	public http: HttpClient,
  	public loadingService : LoadingService,
  	public snackbarCmp : SnackbarComponent,
  	public dialog : DialogComponent,

  	) {

  		  this.headers = new Headers();

		this.headers.append('Access-Control-Allow-Origin' , '*');
		this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
		this.headers.append('Accept','application/json');
		this.headers.append('content-type','application/json');
		this.getLoginUserData();
  	}

	// Payment API
	payment(data:any) {
		let pay = this.url + '/charge';
		console.log(data,pay);
		return new Promise((resolve, reject) => {
		  this.http.post(pay,data).subscribe(
			(res: any) => {
			  console.log(res);
			  resolve(res);
			},
			(err) => {
			  reject(err);
			  console.log(err);
			}
		  );
		});
	  }




	loginuser(data: any) {
		let loginurl = this.url + '/client/login';
		console.log(data,loginurl);
		return new Promise((resolve, reject) => {
		  this.http.post(loginurl, data).subscribe(
			(res: any) => {
			  console.log(res);
			  resolve(res);
			},
			(err) => {
			  reject(err);
			  console.log(err);
			}
		  );
		});
	  }

	  forgot(data: any) {
		let forgeturl = this.url + '/Forgot-Password';
		console.log(data,forgeturl);
		return new Promise((resolve, reject) => {
		  this.http.post(forgeturl, data).subscribe(
			(res: any) => {
			  console.log(res);
			  resolve(res);
			},
			(err) => {
			  reject(err);
			  console.log(err);
			}
		  );
		});
	  }

	//   getinfluencer(){
	// 		let getall = this.url + '/influencer/get';
	// 		console.log(getall);
	// 		return new Promise((resolve, reject) => {
	// 		  this.http.get(getall).subscribe(
	// 			(res: any) => {
	// 			  console.log(res);
	// 			  resolve(res);
	// 			},
	// 			(err) => {
	// 			  reject(err);
	// 			  console.log(err);
	// 			}
	// 		  );
	// 		});
	//   }

	

  	getLoginUserData(){
			let loginUserData = JSON.parse(localStorage.getItem("loginUser"));
			if(loginUserData){
				this.accessToken = loginUserData['id'];
				this.userId = loginUserData['userId'];
			}
			
		}




  login(userInfo) {
    this.loadingService.showLoading();

    let loginUrl = this.apiUrl + 'users/login';
    

  	
	  return new Promise((resolve, reject) => {

		

	    this.http.post(loginUrl, userInfo,{headers: this.headers,withCredentials: true})
	      .subscribe(res => {
	      	localStorage.setItem("loginUser",JSON.stringify(res));
          	// this.loadingService.hideLoading();
	        resolve(res);
	      }, (err) => {
	      	this.loadingService.hideLoading();
	      	this.snackbarCmp.snackbarError(AppSettings.LOGIN_ERROR);
	        reject(err);
	      });
	  });
	}



	forgetPassword(userInfo) {
    this.loadingService.showLoading();

    let forgetUrl = this.apiUrl + 'users/reset';
    

  	
	  return new Promise((resolve, reject) => {


	    this.http.post(forgetUrl, userInfo,{headers: this.headers,withCredentials: true})
	      .subscribe(res => {
          	this.loadingService.hideLoading();
          	this.snackbarCmp.snackbarError(AppSettings.MESSAGE.USER.FORGET_PASSWORD);
	        resolve(res);
	      }, (err) => {
	      	this.loadingService.hideLoading();
	      	this.snackbarCmp.snackbarError(AppSettings.LOGIN_ERROR);
	        reject(err);
	      });
	  });
	}


	resetPassword(userInfo) {
    this.loadingService.showLoading();



    let resetUrl = this.apiUrl + 'users/reset-password?access_token=' + JSON.parse(localStorage.getItem("loginUser")).id;
    

  	
	  return new Promise((resolve, reject) => {

		

	    this.http.post(resetUrl, userInfo,{headers: this.headers,withCredentials: true})
	      .subscribe(res => {
          	this.loadingService.hideLoading();
          	this.snackbarCmp.snackbarError(AppSettings.MESSAGE.USER.RESET_PASSWORD);
	        resolve(res);
	      }, (err) => {
	      	this.loadingService.hideLoading();
	      	this.snackbarCmp.snackbarError(AppSettings.LOGIN_ERROR);
	        reject(err);
	      });
	  });
	}

	resetPasswordforgot(userInfo) {
		this.loadingService.showLoading();
	
	
	
		let resetUrl = this.apiUrl + 'users/reset-password?access_token='+userInfo.accessToken;
		
	
		  
		  return new Promise((resolve, reject) => {
	
			
	
			this.http.post(resetUrl, userInfo,{headers: this.headers,withCredentials: true})
			  .subscribe(res => {
				  this.loadingService.hideLoading();
				  this.snackbarCmp.snackbarError(AppSettings.MESSAGE.USER.RESET_PASSWORD);
				resolve(res);
			  }, (err) => {
				  this.loadingService.hideLoading();
				  this.snackbarCmp.snackbarError(AppSettings.LOGIN_ERROR);
				reject(err);
			  });
		  });
		}
	


	logout(){
		this.getLoginUserData();

		this.loadingService.showLoading();
		
	    let logoutUrl = this.apiUrl + '/users/logout?access_token=' + this.accessToken;
	   

  	
	  return new Promise((resolve, reject) => {

	    this.http.post(logoutUrl, null,{headers:this.headers,withCredentials: true})
	      .subscribe(res => {
          	this.loadingService.hideLoading();
          	localStorage.removeItem("loginUser");
          	localStorage.removeItem("userProfile");
          	this.snackbarCmp.snackbarError(AppSettings.LOGOUT_SUCCESS);
	        resolve(res);
	      }, (err) => {
	      	this.loadingService.hideLoading();
	      	this.snackbarCmp.snackbarError(AppSettings.GENERIC_ERROR);
	        reject(err);
	      });
	  });
	}

	getUserDetails(userIdDetail=null){

		this.getLoginUserData();

		this.loadingService.showLoading();
		if(userIdDetail !== null){
			this.userId = userIdDetail;
		}
		let filterJSON = {
			"include" : {
				"relation" : "roleMappings"
			}
		}
		
		
		let filter = encodeURI(JSON.stringify(filterJSON));
		let profileURL = this.apiUrl + 'users/' + this.userId 
									+ '?access_token=' + this.accessToken
									+ '&filter=' + filter;

		return new Promise((resolve, reject) => {
			this.http.get(profileURL).subscribe(res => {
				if(userIdDetail === null){
					localStorage.setItem("userProfile",JSON.stringify(res));
				}
				this.loadingService.hideLoading();
		        resolve(res);
			},(err)=>{
				this.loadingService.hideLoading();
      			this.snackbarCmp.snackbarError(AppSettings.GENERIC_ERROR);
	        	reject(err);
			});
		});
	}

	getAllUsersFrontend(){
		return this.getAllUsers(AppSettings.ROLE.FRONTEND);
	}
   
	getAllSubadmins(){
		return this.getAllUsers(AppSettings.ROLE.SUBADMIN);
	}
	

	getAllUsers(roleId = AppSettings.ROLE.FRONTEND){
  		this.getLoginUserData();

		this.loadingService.showLoading();
		
		let filterJSON = {
			"include" : [{
						"relation" : "roleMappings",
						"scope" : {
							"where" : {
								"roleId" : roleId
							}
						}
					}],
				"where" : {
					"id" : {
						"neq" : this.userId
					}
				},
				"order": "createdAt DESC"
			};
		

		let filter = encodeURI(JSON.stringify(filterJSON));

		let allUsersURL = this.apiUrl + 'users/' 
									+ '?access_token=' + this.accessToken
									+ '&filter=' + filter;

		return new Promise((resolve, reject) => {
			this.http.get(allUsersURL).subscribe((res:any) => {

				if(roleId != ''){
					res = JSON.parse(JSON.stringify(res));
					let dataRes = [];
					for (var i = res.length - 1; i >= 0; i--) {
						if(res[i]['roleMappings'] !== undefined && res[i]['roleMappings'].length > 0){
							dataRes.push(res[i]);
						}
						if(i==0){
							this.loadingService.hideLoading();
							resolve(dataRes);
						}
				  	}
				}else{
					this.loadingService.hideLoading();
					resolve(res);
				}
			},(err)=>{
				this.loadingService.hideLoading();
      			this.snackbarCmp.snackbarError(AppSettings.GENERIC_ERROR);
	        	reject(err);
			});
		});
	}

	signup(userInfo,roleId) {
		this.getLoginUserData();
		//firstName,lastName,mobileNo,companyName,email,username,password, realm
	    this.loadingService.showLoading();

	    let signupUrl = this.apiUrl + 'users/create-user/' 
	    				+ '?access_token=' + this.accessToken
	    				+  '&roleId=' + roleId;
	   

  	
	  return new Promise((resolve, reject) => {

	    this.http.post(signupUrl, userInfo,{headers:this.headers,withCredentials: true})
	      .subscribe(res => {
          	this.loadingService.hideLoading();
          	this.dialog.openContentDialog(AppSettings.MESSAGE.USER.ADD_TITLE,AppSettings.MESSAGE.USER.ADD);
          	// this.snackbarCmp.snackbarError(AppSettings.MESSAGE.USER.ADD);
	        resolve(res);
	      }, (err) => {
	      	this.loadingService.hideLoading();
	      	this.snackbarCmp.snackbarError(AppSettings.MESSAGE.USER.SIGNUP_ERR);
	        reject(err);
	      });
	  });
	}

	editUser(userInfo,userId,successMsg = AppSettings.MESSAGE.USER.EDIT) {
		  		this.getLoginUserData();

		//firstName,lastName,mobileNo,companyName,email,username,password, realm
	    this.loadingService.showLoading();
	    


	    let editUserUrl = this.apiUrl + 'users/' + userId + '?access_token=' + this.accessToken;
	   

  	
	  return new Promise((resolve, reject) => {

	    this.http.patch(editUserUrl, userInfo,{headers:this.headers,withCredentials: true})
	      .subscribe(res => {
          	this.loadingService.hideLoading();
          	this.snackbarCmp.snackbarError(successMsg);
	        resolve(res);
	      }, (err) => {
	      	this.loadingService.hideLoading();
	      	this.snackbarCmp.snackbarError(AppSettings.GENERIC_ERROR);
	        reject(err);
	      });
	  });
	}

	deleteUser(userId){
		  		this.getLoginUserData();

		//firstName,lastName,mobileNo,companyName,email,username,password, realm

		let dialogTitle = "Delete User";
	    let dialogContent = AppSettings.MESSAGE.USER.DELETE_CONFIRM;
				  return new Promise((resolve, reject) => {
	    
		this.dialog.openDialog(dialogTitle,dialogContent).then((isOkay)=>{
			    if(isOkay){
			    this.loadingService.showLoading();
			    
			    let deleteUserUrl = this.apiUrl + 'users/' + userId + '?access_token=' + this.accessToken;
			   

		  	

			    this.http.delete(deleteUserUrl,{headers:this.headers,withCredentials: true})
			      .subscribe(res => {
		          	this.loadingService.hideLoading();
		          	this.snackbarCmp.snackbarError(AppSettings.MESSAGE.USER.DELETE);
			        resolve(res);
			      }, (err) => {
			      	this.loadingService.hideLoading();
			      	this.snackbarCmp.snackbarError(AppSettings.GENERIC_ERROR);
			        reject(err);
			     });
			    }
				  });

		})
	    
	    
	}


  }