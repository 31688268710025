import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
 valueSetCheck:any;
  show:boolean=false
  show1:boolean=true
  isLogin:boolean = false
  @Input() isInfluencer:boolean = false
  constructor(public route: Router) {
    console.log('router.url',this.route.url)
    let token:any = localStorage.getItem('clientProfile')
    token = token ? JSON.parse(token) : undefined
    if(token && token.token){
      this.isLogin = true
    }else{
      this.isLogin = false
    }
   }

  ngOnInit() {
    if(this.route.url == '/home/client-dashboard' || this.route.url == '/home/client-campaign' || this.route.url == '/home/profile' || 
    this.route.url == '/home/archived-campaigns' || this.route.url == '/home/payment-details' || this.route.url == '/home/live-campaign'){
      this.show=true;
      this.show1=false;
    }
  }

  register(){
    if(this.isInfluencer){
      this.route.navigate(['/sign-up-influencer'])

    }else{
      this.route.navigate(['/sign-up-client'])
    }
    
  }
  registerInfluencer(){
    this.route.navigate(['/sign-up-influencer'])
  }

  login(){
      this.route.navigate(['/login'])
  }

  

  goto(){
    this.route.navigate(['/home/profile'])
  }


  logout(){
    // if(this.route.url == '/home/client-dashboard' || this.router.url == '/home/client-campaign' || this.router.url == '/home/profile'){
  
      window.localStorage.clear()

      this.route.navigate(['/login'])
  }
}
