import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
   providedIn: 'root',
})

export class CheckoutService {

   headers:HttpHeaders
   URL:string = 'https://influencer.stageprojects.xyz/'
   // URL:string = 'http://localhost:4002/'
   constructor(private http: HttpClient) { 
      this.headers = new HttpHeaders();

		this.headers.append('Access-Control-Allow-Origin', '*');
		this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
		this.headers.append('Accept', 'application/json');
		this.headers.append('content-type', 'application/json');
   }

   payment(data) {
      return this.http.post(this.URL+'payment/add', data);
   }
   createCustomer(data) {
      return this.http.post(this.URL+'payment/customer-add', data);
   }
   StartSubscription(data) {
      return this.http.post(this.URL+'payment/subscriptions-add', data);
   }
   updateSubscription(data:string) {
		let url = this.URL+"payment/update/"+data;
		return new Promise((resolve, reject) => {
			this.http.get(url).subscribe(
				(res: any) => {
					console.log(res);
					resolve(res);
				},
				(err) => {
					reject(err);
					console.log(err);
				}
			);
		});
	}
}