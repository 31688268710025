import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-front-dialog',
  templateUrl: './front-dialog.component.html',
  styleUrls: ['./front-dialog.component.css']
})
export class FrontDialogComponent implements OnInit {

  constructor(private dialogref : MatDialogRef<FrontDialogComponent>) { }

  ngOnInit() {
  }

  close(){
    this.dialogref.close({data:'yes'})
  }
  close1(){
    this.dialogref.close({data:'no'})
  }

}
