import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthguardServiceService {

  constructor() { }
  gettoken(){
    let token = localStorage.getItem('clientProfile') ? JSON.parse(localStorage.getItem('clientProfile')).token : undefined
    if(token){
      return true
    }else{
      return false
    }
  }

  
}
